import { Box, Grid } from "@mui/material";
import React, { useState, useContext, useEffect } from "react";

import { UserTypeContext } from "../../context/userStatus";
import { userService } from "../../services/auth";
import {
  isExpandMode,
  isPersonalMode,
  setUserObject,
} from "../../store/actions/userTypeAction";

import useWidth from "../../utils/useWidth";
import { contactsService } from "../../services/contactsService";
import { ContactsContext } from "../../context/userIContacts";
import {
  setAllContacts,
  setGroupContacts,
} from "../../store/actions/contactsActions";

import { useLocation, useNavigate } from "react-router-dom";
import TrackerSnackbar from "./TrackerSnakbar";
import TracingLoader from "./TrackingLoader";
import DesktopView from "./desktopView";
import TrackingView from "../tracker";
import { setArchiveMode } from "../../store/actions/trackerAction";
import { TrackingContext } from "../../context/userTracking";
import LimitReachedPopup from "./limitReachedModal";
import FullpageLoader from "../../components/fullPageLoader";
import TutorialModal from "./tutorialModals";
import FinalAnimation from "../improvements/finalAnimation";
import palette from "../../theme/palette";
import useNotifications from "../../components/DayCompleteSnackBar";
import MobileLinkModal from "./mobileLinkModal";

const TrackingViewNew = () => {
  const { state: IUserTypeState, dispatch: userTypeDispatch } =
    useContext(UserTypeContext);
  const { dispatch: trackingDispatch } =
    useContext(TrackingContext);
  // const [isExpandMode, setIsExpandMode] = useState(false);
  // const [ispersonalMode, setIsPersonalMode] = useState(false);
  const [userDetailsLoaded, setUserDetailsLoaded] = useState(false);
  const [visibleTutorial, setVisibleTutorial] = useState(false);
  const [visibleMobileLoginModal, setVisibleMobileLoginModal] = useState(false);
  const [showFinalAnimation, setShowAnimation] = useState(
    localStorage.getItem("showAnimation") === "true"
  );
  const [isFromOnboard, setIsFromOnboard] = useState(
    localStorage.getItem("showAnimation") === "true"
  );
  const { dispatch: contactsDispatch } =
    useContext(ContactsContext);
  const { Notification } = useNotifications();

  let userValues = JSON.parse(localStorage.getItem("userValues"));
  let location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    const viewType = localStorage.getItem("viewType");
    const mode = localStorage.getItem("mode");
    if (viewType === "expand") {
      userTypeDispatch(isExpandMode(true));
    }
    if (mode === "personal") {
      userTypeDispatch(isPersonalMode(true));
    }
  }, []);

  useEffect(() => {
    trackingDispatch(setArchiveMode((location.state as any)?.direct || false));
  }, [(location.state as any)?.direct]);

  const width = useWidth();

  useEffect(() => {
    getUserDetails();
    getAllContacts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setTimeout(() => {
      console.log("localStorage.getItem(\"showTutorial\")", localStorage.getItem("showTutorial"), visibleTutorial)
      if (localStorage.getItem("showTutorial") === "true" && !visibleTutorial) {
        setVisibleTutorial(true);
      }
    }, 300);
  }, [visibleTutorial, showFinalAnimation]);
  const getAllContacts = () => {
    contactsService
      .getContactsList(IUserTypeState.token)
      .then((data: any) => {
        const { allContact = [], groupData = [] } = data;
        contactsDispatch(setAllContacts(allContact));
        contactsDispatch(
          setGroupContacts(
            groupData.map((it) => {
              if (it.contacts && it.contacts.length) {
                it.contacts = it.contacts.map((ite) => ({
                  ...ite,
                  parent: it._id,
                }));
              }

              return it;
            })
          )
        );
      })
      .catch((error) => {
        console.log("User profile Error");
      });
  };
  const getUserDetails = () => {
    const userId = {
      uid: IUserTypeState?.userId ? IUserTypeState?.userId : userValues?.uid,
    };
    userService
      .getUserById(userId)
      .then((data: any) => {
        if (data?.data?.level === "NOT_FOUND") {
          navigate("/user-identification");
        } else {
          setTimeout(() => {
            if (
              data?.data?.level === "LOW" &&
              (!data?.outcome || !data?.influence)
            ) {
              navigate("/plan-create");
            } else if (
              data?.data?.level === "HIGH" &&
              (!data?.outcome || !data?.influence || !data?.reflective)
            ) {
              navigate("/plan-create");
            }
          }, 2000);
        }
        setUserDetailsLoaded(true);
        userTypeDispatch(
          setUserObject({
            ...data?.data,
            showGoal: data?.showGoal,
          })
        );
        localStorage.setItem("userValues", JSON.stringify(data?.data));
      })
      .catch((error) => {
        console.log("User profile Error");
      });
  };

  return (
    <>
      {!showFinalAnimation && <TracingLoader />}
      <TrackerSnackbar />

      {userDetailsLoaded && !showFinalAnimation ? (
        width > 768 ? (
          <DesktopView />
        ) : (
          <TrackingView />
        )
      ) : (
        !showFinalAnimation && <FullpageLoader />
      )}
      {userDetailsLoaded && visibleTutorial && !showFinalAnimation ? (
        <TutorialModal
          visibleTutorial={visibleTutorial}
          setVisibleTutorial={setVisibleTutorial}
          setVisibleMobileLoginModal={setVisibleMobileLoginModal}
          isMobile={width < 768}
          isFromOnboard={isFromOnboard}
        />
      ) : null}
      {userDetailsLoaded && visibleMobileLoginModal && !showFinalAnimation ? (
        <MobileLinkModal
          visibleMobileLoginModal={visibleMobileLoginModal}
          setVisibleMobileLoginModal={setVisibleMobileLoginModal}
          isMobile={width < 768}
        />
      ) : null}
      {showFinalAnimation && (
        <Grid
          container
          columns={16}
          className={width <= 768 ? "bgImageCommonMobile" : "bgImageCommonNew"}
          sx={{
            backgroundColor: palette.secondary.light,
            position: "absolute",
            zIndex: 99999,
            width: "100%",
            height: "100vh",
          }}
        >
          {" "}
          <Box
            sx={{
              opacity: "100%",
              zIndex: 1,
              width: "100%",
            }}
          >
            <Grid
              item
              sx={{
                display: "flex",
                alignItems:
                  width <= 768 && !showFinalAnimation ? "flex-start" : "center",
                justifyContent: "center",
                width: "100%",
                height: "100vh",
              }}
              className="leftside-menu outcome-screen "
            >
              <FinalAnimation
                dataLoading={true}
                setShowAnimation={setShowAnimation}
              />
            </Grid>
          </Box>
        </Grid>
      )}

      <LimitReachedPopup />
      {Notification}
    </>
  );
};

export default TrackingViewNew;
