import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { auth } from "../config/firebase";
import axios from "axios";
import { baseEndpoints } from "./config";
import { UserReq } from "../interfaces/outcomeModal";
import { trackingService } from "./tracking";

const headerToken = localStorage.getItem("userDetails");
const socialMediaAuth = async (provider: any) => {
  return signInWithPopup(auth, provider)
    .then((result: any) => {
      GoogleAuthProvider.credentialFromResult(result);
      const user = result.user;
      return user;
    })
    .catch((err: any) => {
      return err;
    });
};

const createUser = async (userData: UserReq) => {
  const { data } = await axios
    .post(`${baseEndpoints.createUser}`, userData)
    .then((response) => {
      return response;
    });
  return data;
};

const passcodeVerify = async (userData) => {
  const { data } = await axios
    .post(`${baseEndpoints.passcodeVerify}`, userData)
    .then((response) => {
      return response;
    });
  return data;
};


const joinwaitlist = async (userData: UserReq) => {
  const { data } = await axios
    .post(`${baseEndpoints.waitlist_join}`, userData)
    .then((response) => {
      return response;
    });
  return data;
};

const userLogin = async (uid: string) => {
  const { data } = await axios
    .post(`${baseEndpoints.userLogin}`, { uid: uid, "ipAddress": localStorage.getItem("ipAddress"), "token": localStorage.getItem("currentToken"),
      "deviceTokenType": localStorage.getItem("deviceType"),
      "browserName": localStorage.getItem("browserName"),
     })
    .then((response) => {
      return response;
    });
  return data;
};

const verifyEmail = async (email: string) => {
  const { data } = await axios
    .post(`${baseEndpoints.emailVerify}`, { user_email: email })
    .then((response) => {
      return response;
    });
  return data;
};

const updateUserLevel = async (userData: any) => {
  const { data } = await axios
    .post(`${baseEndpoints.addLevel}`, userData)
    .then((response) => {
      return response;
    });
  return data;
};

const getUserById = async (userId: any) => {
  const { data } = await axios
    .post(`${baseEndpoints.userProfile}/get-user-profile`, {
      ...userId,
      "token": localStorage.getItem("currentToken"),
      "deviceTokenType": localStorage.getItem("deviceType"),
      "browserName": localStorage.getItem("browserName"),
    })
    .then((response) => {
      return response;
    });
  return data;
};

const deleteProfileImage = async () => {
  const { data } = await axios
    .post(`${baseEndpoints.userProfile}/delete-profile-image`, {},
      {
        headers: {
          "x-access-token": headerToken,
        },
      })
    .then((response) => {
      return response;
    });
  return data;
};

const reminderCreate = async (body, token = null) => {
  const { data } = await axios
    .post(`${baseEndpoints.reminderCreate}`, body, {
      headers: {
        "x-access-token": token ? token : headerToken,
      },
    })
    .then((response) => {
      return response;
    });
  return data;
}
const getIPData = async () => {
  const res = await axios.get('https://ipapi.co/json/', {
    withCredentials: false
  });
  localStorage.setItem("ipAddress", res.data.ip)
  const alreadySent = localStorage.getItem("alreadySentEvent")
  if(!["true", true].includes(alreadySent) && res.data.ip) {
    trackingService.HomeVisitedEvent({
      "ipAddress": res.data.ip
    }).then(data => {
      localStorage.setItem("alreadySentEvent", "true")
    }).catch(err => {
      console.log("err", err)
    })
  }
};
const reminderList = async (body, token = null) => {
  const { data } = await axios
    .post(`${baseEndpoints.reminderList}`, body, {
      headers: {
        "x-access-token": token ? token : headerToken,
      },
    })
    .then((response) => {
      return response;
    });
  return data;
}
const setUserImage = async (body: any, token: string) => {
  const { data } = await axios
    .post(`${baseEndpoints.setProfileImage}`, body, {
      headers: {
        "x-access-token": token ? token : headerToken,
      },
    })
    .then((response) => {
      return response;
    });
  return data;
};

const setUserInfo = async (body: any, token: string) => {
  const { data } = await axios
    .post(`${baseEndpoints.setUserInfo}`, body, {
      headers: {
        "x-access-token": token ? token : headerToken,
      },
    })
    .then((response) => {
      return response;
    });
  return data;
};

const outComeGoals = async (body: any, token: string) => {
  const { data } = await axios
    .post(`${baseEndpoints.outComeGoals}`, body, {
      headers: {
        "x-access-token": token ? token : headerToken,
      },
    })
    .then((response) => {
      return response;
    });
  return data;
};

const getGoalsMenu = async (body: any, token: string) => {
  const { data } = await axios
    .post(`${baseEndpoints.goalsMenu}`, body, {
      headers: {
        "x-access-token": token ? token : headerToken,
      },
    })
    .then((response) => {
      return response;
    });
  return data;
};

const influenceGoal = async (body: any, token: string) => {
  const { data } = await axios
    .post(`${baseEndpoints.influenceGoal}`, body, {
      headers: {
        "x-access-token": token ? token : headerToken,
      },
    })
    .then((response) => {
      return response;
    });
  return data;
};
const outComeGoalsCreate = async (body: any, token: string) => {
  const { data } = await axios
    .post(`${baseEndpoints.outComeGoalsCreate}`, body, {
      headers: {
        "x-access-token": token ? token : headerToken,
      },
    })
    .then((response) => {
      return response;
    });
  return data;
};

const outComeGoalsUpdate = async (body: any, token: string) => {
  const { data } = await axios
    .post(`${baseEndpoints.updateOutcomeData}`, body, {
      headers: {
        "x-access-token": token ? token : headerToken,
      },
    })
    .then((response) => {
      return response;
    });
  return data;
};

const influenceGoalsCreate = async (body: any, token: string) => {
  const { data } = await axios
    .post(`${baseEndpoints.influenceGoalsCreate}`, body, {
      headers: {
        "x-access-token": token ? token : headerToken,
      },
    })
    .then((response) => {
      return response;
    });
  return data;
};

const updateInfluenceData = async (body: any, token: string) => {
  const { data } = await axios
    .post(`${baseEndpoints.updateInfluenceData}`, body, {
      headers: {
        "x-access-token": token ? token : headerToken,
      },
    })
    .then((response) => {
      return response;
    });
  return data;
};

const reflectiveGoal = async (selectesReflactiveId: any, token: string) => {
  const { data } = await axios
    .post(`${baseEndpoints.reflectiveGoal}`, { reflectiveId: selectesReflactiveId }, {
      headers: {
        "x-access-token": token ? token : headerToken,
      },
    })
    .then((response) => {
      return response;
    });
  return data;
};

const getReflactiveQuestion = async (token: string) => {
  const { data } = await axios
    .post(`${baseEndpoints.getReflactiveQuestion}`, {}, {
      headers: {
        "x-access-token": token ? token : headerToken,
      },
    })
    .then((response) => {
      return response;
    });
  return data;
};

const createReflactiveQuestionGole = async (body: any, token: string) => {
  const { data } = await axios
    .post(`${baseEndpoints.createReflactiveQuestionGole}`, body, {
      headers: {
        "x-access-token": token ? token : headerToken,
      },
    })
    .then((response) => {
      return response;
    });
  return data;
};

const updateReflactiveGole = async (body: any, token: string) => {
  const { data } = await axios
    .post(`${baseEndpoints.updateReflactiveGole}`, body, {
      headers: {
        "x-access-token": token ? token : headerToken,
      },
    })
    .then((response) => {
      return response;
    });
  return data;
};

const getArchiveData = async (body: any, token: string) => {
  const { data } = await axios
    .post(`${baseEndpoints.getArchiveData}`, body, {
      headers: {
        "x-access-token": token ? token : headerToken,
      },
    })
    .then((response) => {
      return response;
    });
  return data;
};

const archiveOutComeData = async (body: any, token: string) => {
  const { data } = await axios
    .post(`${baseEndpoints.archiveOutComeData}`, body, {
      headers: {
        "x-access-token": token ? token : headerToken,
      },
    })
    .then((response) => {
      return response;
    });
  return data;
};

const deleteArchiveOutComeData = async (body: any, token: string) => {
  const { data } = await axios
    .post(`${baseEndpoints.deleteArchiveOutComeData}`, body, {
      headers: {
        "x-access-token": token ? token : headerToken,
      },
    })
    .then((response) => {
      return response;
    });
  return data;
};

const archiveInfluanceData = async (body: any, token: string) => {
  const { data } = await axios
    .post(`${baseEndpoints.archiveInfluanceData}`, body, {
      headers: {
        "x-access-token": token ? token : headerToken,
      },
    })
    .then((response) => {
      return response;
    });
  return data;
};

const deleteArchiveInfluanceData = async (body: any, token: string) => {
  const { data } = await axios
    .post(`${baseEndpoints.deleteArchiveInfluanceData}`, body, {
      headers: {
        "x-access-token": token ? token : headerToken,
      },
    })
    .then((response) => {
      return response;
    });
  return data;
};

const archiveReflectiveData = async (body: any, token: string) => {
  const { data } = await axios
    .post(`${baseEndpoints.archiveReflectiveData}`, body, {
      headers: {
        "x-access-token": token ? token : headerToken,
      },
    })
    .then((response) => {
      return response;
    });
  return data;
};

const deleteArchiveReflectiveData = async (body: any, token: string) => {
  const { data } = await axios
    .post(`${baseEndpoints.deleteArchiveReflectiveData}`, body, {
      headers: {
        "x-access-token": token ? token : headerToken,
      },
    })
    .then((response) => {
      return response;
    });
  return data;
};
const uploadFileToServer = async (body: any, token: string, setProgress) => {
  const { data } = await axios
    .post(`${baseEndpoints.uploadData}`, body, {
      headers: {
        "x-access-token": token ? token : headerToken,
      },
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        setProgress(percentCompleted);
      },


    })
    .then((response) => {
      return response;
    });
  return data;
}


const uploadAccurateFileToServer = async (body: any, token: string, setProgress) => {
  const { data } = await axios
    .post(`${baseEndpoints.uploadAccurateData}`, body, {
      headers: {
        "x-access-token": token ? token : headerToken,
      },
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        setProgress(percentCompleted);
      },


    })
    .then((response) => {
      return response;
    });
  return data;
}

const exportFileFromServer = async (body: any, token: string) => {
  const { data } = await axios
    .post(`${baseEndpoints.exportData}`, body, {
      headers: {
        "x-access-token": token ? token : headerToken,
      },
      // responseType: 'arraybuffer',
    })
    .then((response) => {
      return response;
    });
  return data;
}

const provisionRespones = async (body: any, token: string) => {
  const { data } = await axios
    .post(`${baseEndpoints.importData}`, body, {
      headers: {
        "x-access-token": token ? token : headerToken,
      },
    })
    .then((response) => {
      return response;
    });
  return data;
}
export const userService = {
  socialMediaAuth,
  createUser,
  userLogin,
  verifyEmail,
  updateUserLevel,
  getUserById,
  outComeGoals,
  getGoalsMenu,
  influenceGoal,
  outComeGoalsCreate,
  influenceGoalsCreate,
  reflectiveGoal,
  getReflactiveQuestion,
  createReflactiveQuestionGole,
  outComeGoalsUpdate,
  updateInfluenceData,
  updateReflactiveGole,
  getArchiveData,
  archiveOutComeData,
  archiveInfluanceData,
  archiveReflectiveData,
  deleteArchiveReflectiveData,
  deleteArchiveInfluanceData,
  deleteArchiveOutComeData,
  setUserImage,
  setUserInfo,
  joinwaitlist,
  passcodeVerify,
  deleteProfileImage,
  reminderCreate,
  reminderList,
  getIPData,
  uploadFileToServer,
  exportFileFromServer,
  provisionRespones,
  uploadAccurateFileToServer
};
