import React, { useContext } from 'react';
import {
    Box,
    Dialog,
} from "@mui/material";

import CommonTypo from '../../components/CommonTypo';
import QRCode from "react-qr-code";
import NewButton from '../../components/NewButton';
import { UserTypeContext } from '../../context/userStatus';

const MobileLinkModal = ({ visibleMobileLoginModal, setVisibleMobileLoginModal, isMobile = false }) => {
    const { state: outcomeState } =
        useContext(UserTypeContext);
    let paperStyles: any = {
        display: 'flex !important',
        padding: '32px',
        flexDirection: 'column !important',
        alignItems: 'flex-start !important',
        gap: '32px !important',
        minWidth: "560px",
        maxWidth: "max-content",
        borderRadius: "12px"
    }
    let paperStylesMobile: any = {
        display: 'flex !important',
        padding: '20px',
        flexDirection: 'column !important',
        alignItems: 'flex-start !important',
        gap: '16px !important',
        minWidth: "90%",
        borderRadius: "20px"
    }
    const host = window.location
    let userValues = JSON.parse(localStorage.getItem("userValues"));
    return <>
        <Dialog
            onClose={() => { }}
            open={visibleMobileLoginModal}
            className={!isMobile && "dialogWrapper"}
            disableEscapeKeyDown
            sx={{
                "& .MuiDialog-paper": isMobile ? paperStylesMobile : paperStyles
            }}
        >
            <Box display={"flex"} alignItems={"flex-start"} gap={"32px"}>
                <Box display={"flex"} flexDirection={"column"} alignItems={"flex-start"} gap={"16px"} width={"372px"}>
                    <CommonTypo size={24} lineHeight={24} fontWeight={700} color='var(--color-text-primary)'>Link Up Your Mobile</CommonTypo>
                    <CommonTypo size={16} lineHeight={24} fontWeight={400} color='var(--color-text-secondary)'>Ready to track on the go? After scanning the QR code, simply sign in on your mobile to link your account. This will allow us to send you helpful notifications, so you can stay on track wherever you are</CommonTypo>
                </Box>
                <Box height={"160px"} width={"160px"}>
                    <QRCode
                        size={160}
                        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                        value={`${host.origin}/link-mobile?authToken=${localStorage.getItem("userDetails")}&uid=${outcomeState?.userId ? outcomeState?.userId : userValues?.uid}`}
                        viewBox={`0 0 160 160`}
                    />
                </Box>
            </Box>
            <Box display={"flex"} alignItems={"center"} gap={"8px"} justifyContent={"space-between"} alignSelf={"stretch"} maxWidth={"564px"}>
                <NewButton buttonText="Skip" variant='text' extraSx={{
                    minWidth: "66px",
                    padding: "16px",
                    height: "40px",
                    borderRadius: "20px"
                }}
                    typographySx={{
                        fontSize: "16px !important",
                        color: "var(--color-text-primary)",
                        fontWeight: `400 !important`
                    }} onClick={() => {
                        setVisibleMobileLoginModal(false)
                    }} />
                <Box sx={{
                    border: "1px solid #EEEEEE",
                    width: "100%",
                    minWidth: "480px",
                    padding: "16px",
                    height: "53px",
                    borderRadius: "26px",
                    display: "flex",
                    alignItems: "center",
                    gap: "8px"
                    , justifyContent: "space-between",
                    position: "relative"
                }}>
                    <CommonTypo size={16} lineHeight={20.8} fontWeight={400} color='var(--color-text-primary)'>{host.origin}/login</CommonTypo>
                    <NewButton buttonText="Copy Link" extraSx={{
                        minWidth: "114px",
                        padding: "16px",
                        height: "40px",
                        borderRadius: "20px",
                        position: "absolute",
                        right: 8
                    }}
                        boxSx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                        typographySx={{
                            fontSize: "16px !important",
                            color: "var(--color-text-primary)",
                            fontWeight: `600 !important`
                        }}
                        padding="16px"
                        borderRadius="20px" onClick={() => {
                            navigator.clipboard.writeText(`${host.origin}/login`)
                        }} />
                </Box>
            </Box>

        </Dialog>
    </>
}

export default MobileLinkModal
