import { useEffect, useState } from "react"
import { app, vapidKey } from "./config/firebase";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { Alert, Box, Snackbar } from "@mui/material";
import React from "react";
import CommonTypo from "./components/CommonTypo";
import Slide from '@mui/material/Slide';
import { trackingService } from "./services/tracking";

function SlideTransition(props) {
    return <Slide {...props} direction="up" />;
}
const BrowserNotificationsApp = () => {
    const [activeTab, setActiveTab] = useState(true)
    const [showSnakbar, setShowSnakbar] = useState(false)
    const [snackbarTitle, setShakbartitle] = useState("")
    const [snackbarBody, setShakbarbody] = useState("")
    useEffect(() => {
        // Request notification permission and set up messaging if supported
        console.log("window", window);
        
        if ("Notification" in window && "serviceWorker" in navigator) {
            requestNotificationPermission();
            getAndStoreToken();
            handleForegroundMessages();
        } else {
            console.log("Notifications or Service Workers are not supported on this browser.");
        }

        document.addEventListener('visibilitychange', () => {
            setActiveTab(!document.hidden);
        });
    }, []);
    const getAndStoreToken = async () => {
        try {
            const userAgent = navigator.userAgent.toLowerCase();
            let browserName = 'Unknown Browser';
            
            if (userAgent.includes('chrome') && !userAgent.includes('edg')) {
                browserName = 'Chrome';
            } else if (userAgent.includes('safari') && !userAgent.includes('chrome')) {
                browserName = 'Safari';
            } else if (userAgent.includes('firefox')) {
                browserName = 'Firefox';
            } else if (userAgent.includes('opera') || userAgent.includes('opr')) {
                browserName = 'Opera';
            } else if (userAgent.includes('msie') || userAgent.includes('trident')) {
                browserName = 'Internet Explorer';
            } else if (userAgent.includes('edg')) {
                browserName = 'Edge';
            }
            localStorage.setItem("browser", browserName)
            let deviceType = "Desktop"
            // Detect device type
            if (/android/i.test(userAgent)) {
                deviceType = "AndroidMobile"
            } else if (/iphone|ipad|ipod/i.test(userAgent)) {
                deviceType = "iOSMobile"
            }
            localStorage.setItem("deviceType", deviceType)
            const permission = await Notification.requestPermission();
            if (permission === "granted") {
                console.log("Notification permission granted.");
                if ("serviceWorker" in navigator) {
                    navigator.serviceWorker
                        .register("/firebase-messaging-sw.js")
                        .then(async (registration) => {
                            if (typeof window !== "undefined") {
                                // Now we can get the token
                                const messaging = getMessaging(app);
                                const currentToken = await getToken(messaging, { vapidKey });

                                if (currentToken) {
                                    localStorage.setItem("currentToken", currentToken);
                                } else {
                                    console.log("No registration token available.");
                                }
                            }
                        })
                        .catch((err) => {
                            console.error("Service Worker registration failed:", err);
                        });
                }
            } else if (permission === "denied") {
                console.log("Notification permission denied.");
            } else {
                console.log("Notification permission dismissed.");
            }
        } catch (err) {
            console.log("An error occurred while retrieving token: ", err);
        }
    };
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setShowSnakbar(false);
    };

   

    const requestNotificationPermission = () => {
        if (Notification.permission === "granted") {
            console.log("Notification permission granted.");
        } else if (Notification.permission !== "denied") {
            Notification.requestPermission().then((permission) => {
                if (permission === "granted") {
                    console.log("Notification permission granted.");
                }
            });
        }
    };
    const handleForegroundMessages = () => {
        if ("Notification" in window && "serviceWorker" in navigator) {
            const messaging = getMessaging(app);

            // Handle messages when the app is in the foreground
            onMessage(messaging, (payload) => {
                console.log("Message received in foreground: ", payload);
                // Check for notification permission
                if (Notification.permission === "granted") {
                    // Show notification or handle message in-app
                    const notificationTitle = payload.notification.title;
                    const notificationOptions = {
                        body: payload.notification.body,
                        icon: "/192.png",
                    };

                    // Show a browser notification
                    !activeTab && new Notification(notificationTitle, notificationOptions);
                    if (activeTab) {
                        setShowSnakbar(true);
                        setShakbartitle(notificationTitle)
                        setShakbarbody(notificationOptions.body)
                    }
                } else {
                    console.log("Notification permission not granted");
                }
            });
        }
    };
    return showSnakbar ? <Snackbar TransitionComponent={SlideTransition}
        open={showSnakbar} sx={{
            "& .MuiPaper-root": {
                display: "flex",
                alignItems: "center"
            }
        }} autoHideDuration={5000} onClose={handleClose} anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
        <Alert
            severity="success"
        >
            <Box display={"flex"} flexDirection={"column"} gap={"4px"}>
                <CommonTypo color="var(--color-text-primary)" fontWeight={600}>{snackbarTitle}</CommonTypo>
                <CommonTypo color="var(--color-text-secondary)">{snackbarBody}</CommonTypo>
            </Box>
        </Alert>
    </Snackbar> : null
}

export default BrowserNotificationsApp