import { Box } from "@mui/material"
import React from "react"
import UploadFile from "../settings/uploadFile"
import { userService } from "../../services/auth";
import moment from "moment";
import NewButton from "../../components/NewButton";

const ImportExport = ({
    setShowSnakbar,
    setSnakbarMsg,
    setMsgType
}) => {
    const [isFromExport, setIsFromExport] = React.useState(false);
    const [visibleModal, setVisibleModal] = React.useState(false);
    const handleExportFile = () => {
        const token = localStorage.getItem("userDetails")
        userService.exportFileFromServer({
            from: moment().subtract(7, 'day').format("MM-DD-YYYY"),
            to: moment().format("MM-DD-YYYY"),
        }, token).then((data) => {
            // let blob = new Blob([data]),
            //   downloadUrl = window.URL.createObjectURL(blob),
            //   filename = "TNT-export-file.csv";
            // let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/,
            //   matches = filenameRegex.exec("TNT-export-file");
            // if (matches != null && matches[1]) {
            //   filename = matches[1].replace(/['"]/g, "");
            // }

            // let a = document.createElement("a");
            // if (typeof a.download === "undefined") {
            //   window.location.href = downloadUrl;
            // } else {
            //   a.href = downloadUrl;
            //   a.download = filename;
            //   document.body.appendChild(a);
            //   a.click();
            //   document.body.removeChild(a);
            //   window.URL.revokeObjectURL(downloadUrl);
            // }
        }).catch((err) => {
        })
    }
    const closeModal = (isFromExport = false) => {
        setIsFromExport(isFromExport)
        setVisibleModal(!visibleModal)
        if (isFromExport) {
            handleExportFile()
        }
    }
    return <>
        <Box display={"flex"} alignItems={"center"} gap={"8px"}>
            <NewButton buttonText="Import .xlsx" borderRadius={"20px !important"} padding="16px" extraSx={{
                background: "var(--color-border)",
                border: "none",
                width: "127px",
                height: "40px",
                marginBottom: "0px !important",
                "&:hover": {
                    background: "var(--color-border)"
                },
                "&.MuiButton-outlined": {
                    border: `none`,
                },
                "&.MuiButton-contained": {
                    border: `none`,
                },
            }} fontSize={`16px`} fontWeight={600} typographySx={{
                color: "var(--color-text-primary)",
                fontSize: "16px !important",
                lineHeight: "16px !important",
                fontWeight: `600 !important`,
                letterSpacing: "2% important"
            }} onClick={() => closeModal(false)} />
            <NewButton buttonText="Export .xlsx" borderRadius={"20px !important"} padding="16px" extraSx={{
                background: "var(--color-border)",
                border: "none",
                width: "127px",
                height: "40px",
                marginBottom: "0px !important",
                "&:hover": {
                    background: "var(--color-border)"
                },
                "&.MuiButton-outlined": {
                    border: `none`,
                },
                "&.MuiButton-contained": {
                    border: `none`,
                },
            }} fontSize={`16px`} fontWeight={600} typographySx={{
                color: "var(--color-text-primary)",
                fontSize: "16px !important",
                lineHeight: "16px !important",
                fontWeight: `600 !important`,
                letterSpacing: "2% important"
            }} onClick={() => closeModal(true)} />
        </Box>
        <UploadFile closeModal={closeModal} visibleModal={visibleModal} isFromExport={isFromExport} />
    </>
}

export default ImportExport