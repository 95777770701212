import { Box, Checkbox, FormControlLabel, SxProps, Theme, Typography } from "@mui/material"
import React, { useState } from "react"
import { pxToRem } from "../../../theme/typography";
import palette from "../../../theme/palette";
import CSVFileIcon from "../../../assets/images/icon/CSVFileIcon";

const CommonTypo = ({
    color = palette.common.black,
    size = 16,
    mobileSize,
    fontWeight = 400,
    lineHeight = 16,
    extraSx = {},
    children
}: {
    color?: string;
    size?: number;
    mobileSize?: number
    lineHeight?: number
    fontWeight?: number;
    extraSx?: SxProps<Theme>;
    boxSx?: SxProps<Theme>;
    typographySx?: SxProps<Theme>;
    children: React.ReactNode
}) => {
    return <Typography component={"span"} sx={{
        color: color,
        fontSize: `${pxToRem(size)} !important`,
        lineHeight: `${lineHeight ? pxToRem(lineHeight) : "120%"} !important`,
        fontWeight: fontWeight,
        "&.MuiTypography-root": {
            "@media  (max-width: 600px) and (min-width: 100px)": {
                fontSize: `${pxToRem(mobileSize || size)} !important`,
                lineHeight: "100% !important"
            },
        },
        ...extraSx
    }}>{children}</Typography>
}

const StepperUpload = ({
    onDragEnter,
    onDragOver,
    onDragLeave,
    onDrop,
    handleUploadFile,
    isDragging
}) => {
    const [currentStep, setCurrentStep] = useState(1)
    const [checkedKeys, setCheckedKeys] = useState([])
    return <Box sx={{
        gap: '32px',
        display: 'flex',
        flexDirection: "column",
        width: "880px",
        minHeight: currentStep === 1 ? "500px" : "auto"
    }}>
        {currentStep === 1 ? <Step1 currentStep={currentStep} setCurrentStep={setCurrentStep} /> : null}
        {currentStep === 2 ? <Step2 currentStep={currentStep} setCurrentStep={setCurrentStep} /> : null}
        {currentStep === 3 ? <Step3 currentStep={currentStep} setCurrentStep={setCurrentStep} /> : null}
        {currentStep === 4 ? <Step4 currentStep={currentStep} setCurrentStep={setCurrentStep} /> : null}
        {currentStep === 5 ? <Step5 currentStep={currentStep} setCurrentStep={setCurrentStep} /> : null}
        {currentStep === 6 ? <Step6 currentStep={currentStep} setCurrentStep={setCurrentStep} checkedKeys={checkedKeys}
            setCheckedKeys={setCheckedKeys}
            handleUploadFile={handleUploadFile}
            onDragEnter={onDragEnter}
            onDragOver={onDragOver}
            onDragLeave={onDragLeave}
            onDrop={onDrop}
            isDragging={isDragging}
        /> : null}
    </Box>
}

export default StepperUpload

const Step1 = ({ currentStep, setCurrentStep }) => {
    return <>
        <Box sx={{
            gap: '32px',
            display: 'flex',
            flexDirection: "column",
            width: "880px",
            minHeight: "510px",
            justifyContent: "space-between"
        }}>
            <TitlePart title={"Prepare Your Data for Import"} description={"Before uploading your .xlsx file, please ensure it meets our formatting criteria. We’ll guide you through the necessary steps to format your table correctly."} />
            <Stpper currentStep={currentStep} setCurrentStep={setCurrentStep} />
        </Box>

    </>
}

const Step2 = ({ currentStep, setCurrentStep }) => {
    return <>
        <CommonStepper currentStep={currentStep} setCurrentStep={setCurrentStep} count={1} description="Here’s the list of changes you need to do:"
            title="Make sure the structure is right"
            image={<img src={"import/step2.svg"} alt="step2" />}
            stepDescription={"Pay close attention to the table. We list the influences vertically in column A and the answers in the subsequent columns."}
            stepTitle="Check the overall file structure" />
    </>
}

const Step3 = ({ currentStep, setCurrentStep }) => {
    return <>
        <CommonStepper currentStep={currentStep} setCurrentStep={setCurrentStep} count={2} description="Here’s the list of changes you need to do:"
            title="Make sure the structure is right"
            image={<Box sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minHeight: "221px",
                background: "#EEEEEE",
                borderRadius: "12px"
            }}>
                <img src={"import/step3.svg"} alt="step3" />
            </Box>}
            stepDescription={<>As mentioned, the list of influences is in column A. Under each influence, there is a comment. It is<CommonTypo fontWeight={700} size={16} lineHeight={24} color="#3E4240">{" crucial "}</CommonTypo>  to include this comment, even if the answer is blank.</>}
            stepTitle="Row A" />
    </>
}

const Step4 = ({ currentStep, setCurrentStep }) => {
    return <>
        <CommonStepper currentStep={currentStep} setCurrentStep={setCurrentStep} count={2} description="Here’s the list of changes you need to do:"
            title="Make sure the structure is right"
            image={<Box sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minHeight: "221px",
                background: "#EEEEEE",
                borderRadius: "12px"
            }}>
                <img src={"import/step4.svg"} alt="step3" />
            </Box>}
            stepDescription={"In the other rows, we store the dates and answers. Dates should be in the DD-MM-YYYY or D-M-YYYY format; otherwise, they won’t be recognized. The answers are straightforward: “yes/no” for binary scales and numbers for the 1-5 scale."}
            stepTitle="Other rows" />
    </>
}

const Step5 = ({ currentStep, setCurrentStep }) => {
    return <>
        <CommonStepper currentStep={currentStep} setCurrentStep={setCurrentStep} count={3} description="Here’s the list of changes you need to do:"
            title="Make sure the structure is right"
            image={<Box sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minHeight: "221px",
                background: "#EEEEEE",
                borderRadius: "12px"
            }}>
                <img src={"import/step5.svg"} alt="step3" />
            </Box>}
            stepDescription={"If your metric is a custom question, simply add a special symbol, called the Section sign (§), in brackets, and we’ll understand!"}
            stepTitle="How to import questions?" />
    </>
}

const Step6 = ({ currentStep, setCurrentStep, checkedKeys, setCheckedKeys, handleUploadFile,
    onDragEnter,
    onDragOver,
    onDragLeave,
    onDrop,
    isDragging
}) => {
    const checbox = [
        {
            title: "Overall Table Structure: Influences in row A, answers in row B",
            value: 1
        },
        {
            title: "Each influence has “comment” line right under it",
            value: 2
        },
        {
            title: "Date is in the DD-MM-YYYY or D-M-YYYY format",
            value: 3
        },
        {
            title: "The answers are straightforward: “yes/no” for binary scales and numbers for the 1-5 scale.",
            value: 4
        },
        {
            title: "Questions have section sign (§) in the brackets",
            value: 5
        }
    ]
    let isDisabled = checkedKeys.length !== 5
    return <>
        <Box sx={{
            gap: '32px',
            display: 'flex',
            flexDirection: "column",
            width: "880px",
            minHeight: "auto",
            justifyContent: "space-between"
        }}>
            <Box sx={{
                gap: '32px',
                display: 'flex',
                flexDirection: "column",
                width: "100%",
            }}>
                <TitlePart title={"Import from a .xlsx file"} />
                <Box sx={{
                    gap: '8px',
                    display: 'flex',
                    flexDirection: "column",
                    width: "100%",
                }}>
                    <CommonTypo fontWeight={600} size={16} lineHeight={16} color="#3E4240">{"Checklist"}</CommonTypo>
                    {checbox.map(it => (
                        <CommonCheckbox key={it.value} checked={checkedKeys.includes(it.value)} title={it.title}
                            onChange={event => {
                                if (event.target.checked) {
                                    const currentKeys = [...checkedKeys]
                                    currentKeys.push(it.value)
                                    setCheckedKeys(currentKeys)
                                } else {
                                    const currentKeys = [...checkedKeys].filter(ite => ite !== it.value)
                                    setCheckedKeys(currentKeys)
                                }
                            }} />
                    ))}
                </Box>


                <Box sx={{
                    width: '100%',
                    height: '250px',
                    borderRadius: '8px',
                    border: isDisabled ? "2px dashed #EEEEEE" : isDragging ? '2px dashed var(--primary-color)' : '2px dashed #DCE5E0',
                    display: 'flex',
                    padding: "24px",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    position: 'relative',
                    gap: "16px",
                    backgroundColor: isDragging ? 'rgb(17 115 74 / 20%)' : 'transparent',
                }} onDragEnter={onDragEnter}
                    onDragOver={onDragOver}
                    onDragLeave={onDragLeave}
                    onDrop={onDrop}>

                    <Box >
                        {/* <CSVFileIcon color={isDragging ? "#11734A" : "#656565"} /> */}
                        <svg width="74" height="89" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M20.2959 7.955L15.0459 2.705C14.9415 2.60039 14.8174 2.5174 14.6808 2.46078C14.5442 2.40416 14.3978 2.37501 14.25 2.375H5.25C4.75272 2.375 4.27581 2.57254 3.92417 2.92417C3.57254 3.27581 3.375 3.75272 3.375 4.25V20.75C3.375 21.2473 3.57254 21.7242 3.92417 22.0758C4.27581 22.4275 4.75272 22.625 5.25 22.625H18.75C19.2473 22.625 19.7242 22.4275 20.0758 22.0758C20.4275 21.7242 20.625 21.2473 20.625 20.75V8.75C20.625 8.45187 20.5066 8.16593 20.2959 7.955ZM15 5.84375L17.1562 8H15V5.84375ZM5.625 20.375V4.625H12.75V9.125C12.75 9.42337 12.8685 9.70952 13.0795 9.9205C13.2905 10.1315 13.5766 10.25 13.875 10.25H18.375V20.375H5.625Z" fill="#3E4240" />
                        </svg>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        alignItems: "center",
                        justifyContent: 'center',
                    }}>
                        {isDisabled ? <Typography
                            variant="body1"
                            component="span"
                            sx={
                                {
                                    color: '#656565',
                                    fontWeight: 400,
                                    fontSize: `${pxToRem(16)} !important`,
                                    lineHeight: `${pxToRem(20)}  !important`,
                                    width: "80%",
                                    textAlign: "center"
                                }
                            }
                        >
                            To upload the table, ensure that every item in the list above is checked
                        </Typography> : <>
                            <Typography
                                variant="body1"
                                component="span"
                                sx={
                                    {
                                        color: '#19442E',
                                        fontWeight: 400,
                                        fontSize: `${pxToRem(16)} !important`,
                                        lineHeight: `${pxToRem(20)}  !important`,
                                        textDecoration: 'underline'
                                    }
                                }
                            >
                                Open a XLSX file
                            </Typography>
                            <Typography
                                variant="body1"
                                component="span"
                                sx={
                                    {
                                        color: '#656565',
                                        fontWeight: 400,
                                        fontSize: `${pxToRem(16)} !important`,
                                        lineHeight: `${pxToRem(20)}  !important`,
                                    }
                                }
                            >
                                , or drag and drop it here
                            </Typography>
                        </>}

                    </Box>
                    <input
                        type="file"
                        accept=".xlsx"
                        style={{
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            opacity: 0,
                            cursor: isDisabled ? "not-allowed" : 'pointer',
                        }}
                        disabled={isDisabled}
                        onChange={(event) => {
                            const file = event.target.files[0];
                            if (file) {
                                handleUploadFile(file)
                            }
                        }}
                    />
                </Box>
            </Box>
            <Stpper currentStep={currentStep} setCurrentStep={setCurrentStep} />
        </Box>
    </>
}

const CommonCheckbox = ({
    checked, title,
    onChange
}) => {
    return <FormControlLabel
        value="no"
        control={
            <Checkbox
                // disabled={props.influenceData?.uid}
                checked={checked}
                sx={{
                    color: "#49454F",
                    '& .MuiSvgIcon-root': { fontSize: 18, borderRadius: "2px", },
                    '&.Mui-checked': {
                        color: "#65558F",
                    },
                    "&.MuiCheckbox-root": {
                        padding: "0px 9px"
                    }
                }}
            />
        }
        label={title}
        onChange={onChange}
    />
}

const TitlePart = ({
    title = null,
    description = null
}) => {
    return <Box sx={{
        display: "flex",
        flexDirection: "column",
        gap: "8px"
    }}>
        <CommonTypo fontWeight={700} size={24} lineHeight={24} color="#3E4240">{title}</CommonTypo>
        {description && <CommonTypo fontWeight={400} size={16} lineHeight={24} color="#3E4240">{description}</CommonTypo>}
    </Box>
}

const Stpper = ({
    currentStep, setCurrentStep
}) => {
    const steps = [];
    for (let index = 0; index < 5; index++) {
        steps.push(<Box sx={{
            width: "61.6px",
            height: "8px",
            borderRadius: "50px",
            background: index + 1 <= currentStep ? "var(--color-new-main)" : "#EEEEEE"
        }}></Box>)

    }
    return <Box sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",

    }}>
        <Box sx={{
            display: "flex",
            alignItems: "center",
            gap: "8px"
        }}>
            {steps}
        </Box>
        <Box sx={{
            display: "flex",
            alignItems: "center",
            gap: "16px"
        }}>
            <Box sx={{
                width: "52px",
                height: "40px",
                padding: "16px",
                gap: "8px",
                borderRadius: "20px",
                background: "#EEEEEE",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            }} onClick={() => {
                if (currentStep > 1) {
                    setCurrentStep(currentStep - 1)
                }
            }}>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_108_3941)">
                        <path d="M12.8418 13.825L9.02513 10L12.8418 6.175L11.6668 5L6.6668 10L11.6668 15L12.8418 13.825Z" fill="#656565" />
                    </g>
                    <defs>
                        <clipPath id="clip0_108_3941">
                            <rect width="20" height="20" fill="white" />
                        </clipPath>
                    </defs>
                </svg>

            </Box>
            <Box sx={{
                width: "52px",
                height: "40px",
                padding: "16px",
                gap: "8px",
                borderRadius: "20px",
                background: "var(--color-new-main)",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            }} onClick={() => {
                if (currentStep < 6) {
                    setCurrentStep(currentStep + 1)
                }
            }}>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_108_4325)">
                        <path d="M7.5 6.175L11.3167 10L7.5 13.825L8.675 15L13.675 10L8.675 5L7.5 6.175Z" fill="#656565" />
                    </g>
                    <defs>
                        <clipPath id="clip0_108_4325">
                            <rect width="20" height="20" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            </Box>
        </Box>
    </Box>
}

const CommonStepper = ({
    title = '',
    count = 1,
    description = '',
    image = '',
    currentStep, setCurrentStep,
    stepTitle = '',
    stepDescription = ''
}: {
    title?: string | React.ReactNode,
    description?: string | React.ReactNode,
    image?: React.ReactNode,
    count: number,
    currentStep: number,
    setCurrentStep: (x: number) => void
    stepTitle?: string | React.ReactNode
    stepDescription?: string | React.ReactNode
}) => {
    return <Box sx={{
        gap: '32px',
        display: 'flex',
        flexDirection: "column",
        width: "880px",
        minHeight: "auto",
        justifyContent: "space-between"
    }}>
        <Box sx={{
            gap: '32px',
            display: 'flex',
            flexDirection: "column",
            width: "100%",
        }}>
            <TitlePart title={title} description={description} />
            <Box sx={{
                gap: '8px',
                display: 'flex',
                flexDirection: "column",
                width: "100%",
            }}>
                <Box sx={{
                    gap: '8px',
                    display: 'flex',
                    width: "100%",
                }}>
                    <CommonTypo fontWeight={600} size={16} lineHeight={16} color="#3E4240">{count + "."}</CommonTypo>
                    <CommonTypo fontWeight={600} size={16} lineHeight={16} color="#3E4240">{stepTitle}</CommonTypo>
                </Box>
                <Box sx={{
                    gap: '10px',
                    display: 'flex',
                    width: "100%",
                }}>
                    <CommonTypo fontWeight={400} size={16} lineHeight={24} color="transparent">{count + "."}</CommonTypo>
                    <CommonTypo fontWeight={400} size={16} lineHeight={24} color="#3E4240">{stepDescription}</CommonTypo>
                </Box>

            </Box>
            {image}

        </Box>
        <Stpper currentStep={currentStep} setCurrentStep={setCurrentStep} />
    </Box>
}