import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Box, Paper, TextField } from "@mui/material"
import React, { useContext, useEffect, useState } from "react"
import CommonTypo from "../../components/CommonTypo"
import { trackingService } from "../../services/tracking"
import { UserTypeContext } from "../../context/userStatus"
import useStyles from "./settingclasses"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const FAQ = () => {
    const classes = useStyles()
    const { state: outcomeState } = useContext(UserTypeContext);
    const [FaqData, setFaqData] = useState({})
    const [expanded, setExpanded] = React.useState<string | false>(false);
    const [selectedFAQTab, setSelectedFAQTab] = useState({
        label: "",
        value: ""
    });
    useEffect(() => {
        getFAQs()
    }, [])
    const getFAQs = () => {
        trackingService.getFAQAllTitle(outcomeState.token, {}).then((data) => {
            let allObjects = {}
            for (let i = 0; i < data.data.length; i++) {
                let body = {
                    "type": "APP",
                    "titleId": data.data[i].titleId
                }
                // eslint-disable-next-line no-loop-func
                trackingService.getFAQs(outcomeState.token, body).then((faq) => {

                    allObjects = {
                        ...allObjects,
                        [data.data[i].titleId]: faq.data
                    }
                    if (i === (data.data.length - 1)) {
                        callToSetData()
                    }
                })
            }
            const callToSetData = () => {
                setFaqData(allObjects)
            }
        })
    }
    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };
    useEffect(() => {
        console.log("FaqData", FaqData);

        if (Object.keys(FaqData) && Object.keys(FaqData).length) {
            setSelectedFAQTab({
                value: Object.keys(FaqData)[0],
                label: FaqData[Object.keys(FaqData)[0]][0].title
            });
        }
    }, [FaqData]);
    console.log("selectedFAQTab", selectedFAQTab);

    return <Box display={"flex"} width={"100%"} flexDirection={"column"} gap={"32px"} alignItems={"center"}>
        <Box display={"flex"} width={"85%"} flexDirection={"column"} gap={"32px"} borderBottom={"1px solid var(--color-border)"} paddingBottom={"32px"}>
            <CommonTypo size={24} lineHeight={28.8} fontWeight={700} color="var(--color-text-primary)">Choose the topic</CommonTypo>
            <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={Object.keys(FaqData) && Object.keys(FaqData).length ? Object.keys(FaqData).map((it, index) => {
                    console.log("FaqData[it][0]", FaqData[it][0]);
                    
                    return {
                        // ...it,
                        value: it,
                        label: FaqData[it][0].title as string,
                    }
                }) : []}
                value={selectedFAQTab.label}
                onChange={(e: any, inputValue) => {
                    if (typeof inputValue === "object") {
                        setSelectedFAQTab(inputValue)
                    }
                }}
                sx={{
                    fontSize: "16px"
                }}
                className={classes.countryCodeBox}
                PaperComponent={({ children }) => (
                    <Paper
                        style={{ background: "#FFF" }}
                        sx={{
                            ".MuiAutocomplete-option": {
                                color: "rgb(0, 0, 0)",
                                fontSize: "14px",
                            },
                        }}
                    >
                        {children}
                    </Paper>
                )}
                disableClearable
                placeholder="Select Country"
                renderInput={(params) => (
                    <TextField
                        {...params}
                        sx={{
                            alignSelf: "stretch",
                            borderRadius: "12px",
                            height: "50px",
                            marginBottom: "0px !important",
                            "& .MuiInputBase-root": {
                                borderRadius: "12px !important",
                                height: "48px",
                                "& .MuiInputBase-input": {
                                    borderRadius: "12px",
                                    padding: "8px 8px !important",
                                    "&::placeholder": {
                                        color: "var(--color-text-primary)",
                                        opacity: 1,
                                        fontSize: "16px"
                                    }
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                    display: "none"
                                }
                            }
                        }}
                    />
                )}
            />
        </Box>
        <Box display={"flex"} width={"85%"} flexDirection={"column"} gap={"32px"} paddingBottom={"32px"}>
            {selectedFAQTab.value &&
                FaqData[selectedFAQTab.value] &&
                FaqData[selectedFAQTab.value].length &&
                FaqData[selectedFAQTab.value]?.map((faq, index) => {
                    return (
                        <Accordion
                            key={faq?.titleId + "_" + faq?.question + "_" + index}
                            // defaultExpanded
                            // expanded={expanded === faq?.question}
                            // onChange={handleChange(faq?.question)}
                            sx={{

                                "& .MuiAccordionSummary-root": {
                                    padding: "0px !important",
                                    "& .MuiAccordionSummary-content": {
                                        margin: "0px !important"
                                    },
                                    "&.Mui-expanded": {
                                        minHeight: "48px",
                                        margin: "0px !important"
                                    },
                                },
                                "&.MuiAccordion-rounded": {
                                    marginBottom: "0px",
                                    background: "#FFFFFF",
                                    borderRadius: "12px",
                                },
                                "&.Mui-expanded": {
                                    background: "#FFFFFF",
                                    borderRadius: "12px",
                                    padding: "0px",
                                    minHeight: "0px",
                                    margin: "0px !important"
                                },
                            }}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2bh-content"
                                id="panel2bh-header"
                            >
                                <CommonTypo>{faq?.question}</CommonTypo>
                            </AccordionSummary>
                            <AccordionDetails>
                                <CommonTypo>{faq?.answer}</CommonTypo>
                            </AccordionDetails>
                        </Accordion>
                    );
                })}
        </Box>

    </Box>
}

export default FAQ