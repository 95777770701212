import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { userService } from "./services/auth";
import { UserTypeContext } from "./context/userStatus";
import { setUserObject } from "./store/actions/userTypeAction";
import { TrackingContext } from "./context/userTracking";
import { setSnackBarOption } from "./store/actions/trackerAction";

const LinkToMobile = () => {
    const [queryParams, setQueryParams] = useState({});
    const navigate = useNavigate();
    const { dispatch: userTypeDispatch } =
        useContext(UserTypeContext);
    const { state: ITrackerState, dispatch: trackingDispatch } =
        useContext(TrackingContext);
    useEffect(() => {

        // Get the current URL's search parameters
        const searchParams = new URLSearchParams(window.location.search);
        const paramsObject: {
            authToken?: string;
            uid?: string
        } = {};

        // Convert searchParams to a plain object
        searchParams.forEach((value, key) => {
            paramsObject[key] = value;
        });
        if (!paramsObject.authToken || !paramsObject.uid) {
            navigate("/login")
        } else {
            localStorage.setItem("userDetails", paramsObject.authToken);
            localStorage.setItem("userDetails", paramsObject.authToken);
            const userId = {
                uid: paramsObject.uid,
                mobile_linked: true
            };
            userService
                .getUserById(userId)
                .then((data: any) => {
                    userTypeDispatch(setUserObject(data?.data));
                    localStorage.setItem("userValues", JSON.stringify(data?.data));
                    localStorage.setItem("userDate", JSON.stringify(data?.data?.createdAt));
                    trackingDispatch(setSnackBarOption({
                        showSnakbar: true,
                        snakbarMsg: "Successfully linked mobile",
                        msgType: 'success',
                        snackbarversion: 'v2'
                    }))
                    navigate("/tracking")
                })
                .catch((error) => {
                    console.log("User profile Error");
                });
        }
        setQueryParams(paramsObject); // Update state with the query parameters
    }, []);
    console.log("queryParams", queryParams);

    return null
}

export default LinkToMobile