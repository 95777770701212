import { Box, Dialog, DialogTitle, Typography } from "@mui/material"
import React, { useContext, useEffect, useState } from "react"
import { pxToRem } from "../../theme/typography"
import NewButton from "../../components/NewButton"
import ProgressBar from "./progressbar"
import { userService } from "../../services/auth"
import CSVFileIcon from "../../assets/images/icon/CSVFileIcon"
import StepperUpload from "./stepperUpload"
import ImportFailedBox from "./importFailedData"
import ModalCloseIconBlack from "../../assets/images/icon/modalCloseIconBlack"
import CommonTypo from "../../components/CommonTypo"
import UploadFileCost from "./uploadFileCost"
import { UserTypeContext } from "../../context/userStatus"
let InternalProgress = 0

let newDesign = true
const UploadFile = ({
    visibleModal,
    closeModal,
    isFromExport = false
}) => {
    const [isDragging, setIsDragging] = useState(false);
    const [hasUploadedFile, setUploadedFile] = useState(false);
    const [apiCalled, setApiCalled] = useState(false);
    const [isSelectedVal, setSelectedVal] = useState("");
    const [hasSuccess, sethasSuccess] = useState(false);
    const [failedData, setFailedData] = useState([]);
    const { state: outcomeState, dispatch: userTypeDispatch } =
        useContext(UserTypeContext);
    const [progress, setProgress] = useState(0);
    const handleDragEnter = (event) => {
        event.preventDefault();
        setIsDragging(true);
    };
    useEffect(() => {
        return () => {
            setIsDragging(false)
            setUploadedFile(false)
            setApiCalled(false)
            setProgress(0)
            sethasSuccess(false)
            setSelectedVal(null)
        }
    }, [])
    const runInterval = () => {
        const interval = setInterval(() => {
            console.log("progress <= 100", InternalProgress, InternalProgress <= 100);

            if (InternalProgress < 100) {
                InternalProgress += 20
                setProgress((prevState) => prevState + 20)
            } else {
                clearInterval(interval)
                sethasSuccess(true)
                setApiCalled(true)
            }
        }, 500)
    }
    useEffect(() => {
        if (isFromExport) {
            setUploadedFile(true)
            runInterval()
        }
        return () => {
            setProgress(0)
            InternalProgress = 0
        }
    }, [isFromExport])
    const handleDragLeave = (event) => {
        event.preventDefault();
        setIsDragging(false);
    };

    const handleUploadFile = (file) => {
        setUploadedFile(true)
        console.log(file);
        const token = localStorage.getItem("userDetails")
        const formData = new FormData()
        formData.append("provision", file)
        userService.uploadFileToServer(formData, token, (progress) => {
            console.log("progress", progress);

            setProgress(progress)
        }).then((data) => {
            if (data.data_arr && data.data_arr.length) {
                setFailedData(data.data_arr)
                sethasSuccess(false)
            } else {
                sethasSuccess(true)
            }
            setApiCalled(true)
        }).catch(() => {
            sethasSuccess(false)
            setApiCalled(true)

        })
    }

    const handleUploadFileAccurate = (file) => {
        // setUploadedFile(true)
        console.log(file);
        const token = localStorage.getItem("userDetails")
        const formData = new FormData()
        formData.append("file", file)
        userService.uploadAccurateFileToServer(formData, token, (progress) => {
            console.log("progress", progress);

            setProgress(progress)
        }).then((data) => {
            // if (data.data_arr && data.data_arr.length) {
            //     setFailedData(data.data_arr)
            //     sethasSuccess(false)
            // } else {
            sethasSuccess(true)
            // }
            setApiCalled(true)
        }).catch(() => {
            sethasSuccess(false)
            setApiCalled(true)

        })
    }



    const handleDrop = (event) => {
        event.preventDefault();
        setIsDragging(false);
        const file = event.dataTransfer.files[0];
        if (file) {
            handleUploadFile(file)
        }
    };

    const handleDropAccurate = (event) => {
        event.preventDefault();
        setIsDragging(false);
        const file = event.dataTransfer.files[0];
        if (file) {
            handleUploadFileAccurate(file)
        }
    }

    const handleCloseModal = () => {
        setIsDragging(false)
        setUploadedFile(false)
        setApiCalled(false)
        setProgress(0)
        sethasSuccess(false)
        closeModal()
        setSelectedVal(null)
    }
    return <Dialog
        disableEscapeKeyDown
        onClose={handleCloseModal}
        open={visibleModal}
        className="dialogWrapper dialogWrapper-tracking dialogWrapper-tracking-cal"
        sx={{
            "& .MuiPaper-root": newDesign ? {
                padding: (newDesign && !isSelectedVal) || isSelectedVal === "accurate" ? "0px" : apiCalled ? '40px 32px 36px 32px' : "32px",
                gap: "0px",
                borderRadius: "12px",
                justify: "space-between",
                // height: "620px",
                boxShadow: newDesign ? "0px 0px 64px 0px #190D1A1A" : "0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)",
            } : {
                padding: apiCalled ? '40px 32px 36px 32px' : '48px 40px 48px 40px',
                gap: '48px',
                borderRadius: '20px',
                opacity: '0px',

            }
        }}
    >
        {(!(newDesign && !isSelectedVal) && (isSelectedVal !== "accurate")) && (<DialogTitle style={{
            position: "absolute",
            right: 10,
            top: 10,
            cursor: "pointer",
            zIndex: 1,
        }}>
            <span onClick={handleCloseModal}>
                <ModalCloseIconBlack color={"#000000"} />
            </span>
        </DialogTitle>)}
        <Box sx={newDesign ? {
            gap: '32px',
            display: 'flex',
            flexDirection: "column"
        } : {
            gap: '40px',
            opacity: '0px',
            display: 'flex',
            flexDirection: "column"
        }}>
            {apiCalled ? (
                <Box sx={{
                    gap: '32px',
                    display: 'flex',
                    flexDirection: "column",
                    alignItems: "center"
                }}>
                    {hasSuccess && isSelectedVal !== "accurate" ? (
                        <>
                            <Box sx={{
                                gap: '20px',
                                display: 'flex',
                                flexDirection: "column",
                                alignItems: "center"
                            }}>
                                <Typography
                                    variant="body1"
                                    component="div"
                                    sx={
                                        {
                                            color: '#3E4240',
                                            fontWeight: 600,
                                            fontSize: `${pxToRem(22)} !important`,
                                            lineHeight: "22px  !important",
                                            letterSpacing: '2%'
                                        }
                                    }
                                >
                                    {isFromExport ? "Your export request is being processed" : "Importing succeeded!"}
                                </Typography>
                                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="24" cy="24" r="22.5" stroke={isFromExport ? "var(--color-new-main)" : "#11734A"} stroke-width="3" />
                                    <path d="M21.4853 26.3137L15.8284 20.6569L13 23.4853L21.4853 31.9706L35.6274 17.8284L32.799 15L21.4853 26.3137Z" fill={isFromExport ? "var(--color-new-main)" : "#11734A"} />
                                </svg>
                                {isFromExport && <Typography
                                    variant="body1"
                                    component="div"
                                    sx={
                                        {
                                            color: '#9BA19D',
                                            fontWeight: 400,
                                            fontSize: `${pxToRem(16)} !important`,
                                            lineHeight: "20px  !important",
                                            letterSpacing: '2%',
                                            marginTop: '12px'
                                        }
                                    }
                                >
                                    {"You will receive the file via email shortly"}
                                </Typography>}
                            </Box>
                        </>
                    ) : hasSuccess && isSelectedVal === "accurate" ? (
                        <>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: "column",
                                alignItems: "center",
                                width: "360px",
                                padding: "32px 24px",
                                gap: "32px",
                                borderRadius: "12px"

                            }}>
                                <svg width="84" height="85" viewBox="0 0 84 85" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M42 8.375C35.2507 8.375 28.653 10.3764 23.0412 14.1261C17.4294 17.8758 13.0555 23.2054 10.4726 29.4409C7.88979 35.6765 7.214 42.5379 8.53072 49.1575C9.84744 55.7771 13.0975 61.8576 17.87 66.63C22.6425 71.4025 28.723 74.6526 35.3426 75.9693C41.9622 77.286 48.8236 76.6102 55.0591 74.0274C61.2946 71.4445 66.6242 67.0707 70.3739 61.4588C74.1236 55.847 76.125 49.2493 76.125 42.5C76.1155 33.4524 72.5171 24.7781 66.1195 18.3805C59.7219 11.9829 51.0476 8.38455 42 8.375ZM56.9822 36.4822L38.6072 54.8572C38.3634 55.1013 38.0739 55.2949 37.7552 55.427C37.4366 55.5591 37.095 55.6271 36.75 55.6271C36.4051 55.6271 36.0635 55.5591 35.7448 55.427C35.4261 55.2949 35.1366 55.1013 34.8928 54.8572L27.0178 46.9822C26.5253 46.4896 26.2486 45.8216 26.2486 45.125C26.2486 44.4284 26.5253 43.7604 27.0178 43.2678C27.5104 42.7753 28.1784 42.4985 28.875 42.4985C29.5716 42.4985 30.2396 42.7753 30.7322 43.2678L36.75 49.2889L53.2678 32.7678C53.5117 32.5239 53.8013 32.3305 54.1199 32.1985C54.4386 32.0665 54.7801 31.9985 55.125 31.9985C55.4699 31.9985 55.8115 32.0665 56.1301 32.1985C56.4488 32.3305 56.7383 32.5239 56.9822 32.7678C57.2261 33.0117 57.4196 33.3012 57.5515 33.6199C57.6835 33.9386 57.7515 34.2801 57.7515 34.625C57.7515 34.9699 57.6835 35.3114 57.5515 35.6301C57.4196 35.9488 57.2261 36.2383 56.9822 36.4822Z" fill="#ADE888" />
                                </svg>
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: "column",
                                    alignItems: "center",
                                    gap: "8px",
                                }}>
                                    <CommonTypo size={24} lineHeight={28.8} extraSx={{
                                        textAlign: "center"
                                    }} fontWeight={700} color="var(--color-text-primary)">Thank you</CommonTypo>
                                    <CommonTypo size={16} lineHeight={19.2} extraSx={{
                                        textAlign: "center"
                                    }} fontWeight={400} color="var(--color-text-secondary)">We’ve received your file. We’ll email you once data is imported on this adress:</CommonTypo>
                                    <CommonTypo size={16} lineHeight={19.2} extraSx={{
                                        textAlign: "center"
                                    }} fontWeight={600} color="var(--color-text-secondary)">{outcomeState.userDetails.user_email}</CommonTypo>
                                </Box>
                                <Box sx={{
                                    gap: "16px",
                                    display: "flex",
                                    borderRadius: "12px",
                                    alignItems: "center",
                                    alignSelf: "stretch"
                                }}>
                                    <NewButton
                                        buttonText="Great!"
                                        // onClick={() => updateRescueTime(selectedTab, checkedKeys, closemodal)}
                                        onClick={handleCloseModal}
                                        extraSx={{
                                            background: "var(--color-new-main)",
                                            border: "none",
                                            height: "40px",
                                            flex: 1,
                                            padding: 0,
                                            marginBottom: "0px !important",
                                            "&:hover": {
                                                background: "var(--color-new-main)"
                                            },
                                            "&.MuiButton-outlined": {
                                                border: `none`,
                                            },
                                            "&.MuiButton-contained": {
                                                border: `none`,
                                            },
                                        }} fontSize={`16px`} boxSx={{
                                            padding: 0
                                        }} fontWeight={600} typographySx={{
                                            fontSize: "16px !important",
                                            lineHeight: "16px !important",
                                            fontWeight: `600 !important`,
                                            letterSpacing: "2% important",
                                            whiteSpace: "nowrap"
                                        }}
                                    />
                                </Box>
                            </Box>
                        </>
                    ) : <>
                        <Box sx={{
                            gap: '20px',
                            display: 'flex',
                            flexDirection: "column",
                            alignItems: "flex-start",
                            width: "100%"
                        }}>
                            <Typography
                                variant="body1"
                                component="div"
                                sx={
                                    {
                                        color: 'var(--color-black)',
                                        fontWeight: 700,
                                        fontSize: `${pxToRem(24)} !important`,
                                        lineHeight: `${pxToRem(24)} !important`,
                                        letterSpacing: '2%'
                                    }
                                }
                            >
                                Import failed...
                            </Typography>
                            <ImportFailedBox failedData={failedData}
                                setFailedData={setFailedData}
                                setApiCalled={setApiCalled}
                                sethasSuccess={sethasSuccess}
                                setUploadedFile={setUploadedFile}
                            />
                            {/* <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M47 10.5H7V16.5H47V10.5Z" fill="#F9D412" />
                                <line x1="3" y1="17.5" x2="47" y2="17.5" stroke="#11734A" stroke-width="3" />
                                <path d="M46.5 24.5V10C46.5 7.79086 44.7091 6 42.5 6H6.5C4.29086 6 2.5 7.79086 2.5 10V41.7975C2.5 41.9274 2.55052 42.0522 2.64088 42.1454L17.8527 57.8479C17.9468 57.9451 18.0764 58 18.2118 58H42.5C44.7091 58 46.5 56.2091 46.5 54V42.75" stroke="#11734A" stroke-width="3" />
                                <path d="M2.5 42H16.5C17.6046 42 18.5 42.8954 18.5 44V58L2.5 42Z" stroke="#11734A" stroke-width="3" stroke-linejoin="round" />
                                <path d="M18.5 33.5H61.5" stroke="#11734A" stroke-width="3" />
                                <path d="M54.5 26.5L61.5 33.5L54.5 40.5" stroke="#11734A" stroke-width="3" stroke-linejoin="round" />
                                <path d="M33.1328 27.8418L44.4465 39.1555M33.1328 39.1555L44.4465 27.8418" stroke="white" stroke-width="7" />
                                <path d="M34.1328 28.8418L43.4465 38.1555M34.1328 38.1555L43.4465 28.8418" stroke="#11734A" stroke-width="3" />
                            </svg> */}

                        </Box>
                        {/* <Typography
                            variant="body1"
                            component="div"
                            sx={
                                {
                                    color: 'var(--color-black)',
                                    fontWeight: 400,
                                    fontSize: `${pxToRem(16)} !important`,
                                    lineHeight: "31.2px  !important",
                                    letterSpacing: '2%',
                                    display: "flex",
                                    flexWrap: 'nowrap',
                                    alignItems: "center",
                                    gap: '4px'
                                }
                            }
                        >
                            Please check you connection and  <Typography
                                variant="body1"
                                component="div"
                                onClick={() => {
                                    setIsDragging(false)
                                    setUploadedFile(false)
                                    setApiCalled(false)
                                    setProgress(0)
                                    sethasSuccess(false)
                                }}
                                sx={
                                    {
                                        color: 'var(--primary-color)',
                                        fontWeight: 400,
                                        cursor: "pointer",
                                        fontSize: `${pxToRem(16)} !important`,
                                        textDecoration: 'underline',
                                        lineHeight: "100%  !important",
                                    }
                                }
                            >
                                retry
                            </Typography>
                        </Typography> */}
                    </>}

                </Box>
            ) : hasUploadedFile ? (
                <Box sx={{
                    width: '480px',
                    gap: '16px',
                    display: 'flex',
                    flexDirection: "column"
                }}>
                    <Box sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between"
                    }}>
                        <Typography
                            variant="body1"
                            component="div"
                            sx={
                                {
                                    color: 'var(--color-black)',
                                    fontWeight: 600,
                                    fontSize: `${pxToRem(22)} !important`,
                                    lineHeight: "31.2px  !important",
                                    letterSpacing: '2%'
                                }
                            }
                        >
                            {isFromExport ? "Generating export file..." : "Importing your data..."}
                        </Typography>
                        <Typography
                            variant="body1"
                            component="div"
                            sx={
                                {
                                    color: 'var(--color-black)',
                                    fontWeight: 600,
                                    fontSize: `${pxToRem(22)} !important`,
                                    lineHeight: "31.2px  !important",
                                    letterSpacing: '2%'
                                }
                            }
                        >
                            {progress}%
                        </Typography>
                    </Box>

                    <ProgressBar progress={progress} />
                </Box>

            ) : newDesign && isSelectedVal ? isSelectedVal === "accurate" ? (

                <UploadFileCost
                    onClose={handleCloseModal}
                    onDragEnter={handleDragEnter}
                    onDragOver={(event) => event.preventDefault()}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDropAccurate}
                    handleUploadFile={handleUploadFileAccurate}
                    isDragging={isDragging}
                // onDragEnter={handleDragEnter}
                // onDragOver={(event) => event.preventDefault()}
                // onDragLeave={handleDragLeave}
                // onDrop={handleDrop}
                // handleUploadFile={handleUploadFile}
                // isDragging={isDragging}
                />) : isSelectedVal === "automated" ? <StepperUpload
                    onDragEnter={handleDragEnter}
                    onDragOver={(event) => event.preventDefault()}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                    handleUploadFile={handleUploadFile}
                    isDragging={isDragging}
                /> : null
                : newDesign && !isSelectedVal ? (
                    <Box sx={{
                        width: '424px',
                        gap: '33px',
                        display: 'flex',
                        flexDirection: "column",
                        borderRadius: "16px",
                        alignSelf: "stretch",

                    }}>
                        <Box display={"flex"} alignItems={"center"} borderRadius={"16px 16px 0px 0px"} alignSelf={"stretch"} overflow={"hidden"} position={"relative"}>
                            <img src={'import-image.svg'} alt="login-1" style={{
                                height: '80%'
                            }} />
                            <Box position={"absolute"} top={"4px"} right={"4px"} sx={{
                                cursor: "pointer"
                            }} onClick={() => {
                                setIsDragging(false)
                                setUploadedFile(false)
                                setApiCalled(false)
                                setProgress(0)
                                sethasSuccess(false)
                                closeModal()
                            }}>
                                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 2.75C10.0716 2.75 8.18657 3.32183 6.58319 4.39317C4.97982 5.46451 3.73013 6.98726 2.99218 8.76884C2.25422 10.5504 2.06114 12.5108 2.43735 14.4021C2.81355 16.2934 3.74215 18.0307 5.10571 19.3943C6.46928 20.7579 8.20656 21.6865 10.0979 22.0627C11.9892 22.4389 13.9496 22.2458 15.7312 21.5078C17.5127 20.7699 19.0355 19.5202 20.1068 17.9168C21.1782 16.3134 21.75 14.4284 21.75 12.5C21.7473 9.91498 20.7192 7.43661 18.8913 5.60872C17.0634 3.78084 14.585 2.75273 12 2.75ZM15.5306 14.9694C15.6003 15.0391 15.6556 15.1218 15.6933 15.2128C15.731 15.3039 15.7504 15.4015 15.7504 15.5C15.7504 15.5985 15.731 15.6961 15.6933 15.7872C15.6556 15.8782 15.6003 15.9609 15.5306 16.0306C15.4609 16.1003 15.3782 16.1556 15.2872 16.1933C15.1961 16.231 15.0986 16.2504 15 16.2504C14.9015 16.2504 14.8039 16.231 14.7128 16.1933C14.6218 16.1556 14.5391 16.1003 14.4694 16.0306L12 13.5603L9.53063 16.0306C9.46095 16.1003 9.37822 16.1556 9.28718 16.1933C9.19613 16.231 9.09855 16.2504 9 16.2504C8.90146 16.2504 8.80388 16.231 8.71283 16.1933C8.62179 16.1556 8.53906 16.1003 8.46938 16.0306C8.3997 15.9609 8.34442 15.8782 8.30671 15.7872C8.269 15.6961 8.24959 15.5985 8.24959 15.5C8.24959 15.4015 8.269 15.3039 8.30671 15.2128C8.34442 15.1218 8.3997 15.0391 8.46938 14.9694L10.9397 12.5L8.46938 10.0306C8.32865 9.88989 8.24959 9.69902 8.24959 9.5C8.24959 9.30098 8.32865 9.11011 8.46938 8.96937C8.61011 8.82864 8.80098 8.74958 9 8.74958C9.19903 8.74958 9.3899 8.82864 9.53063 8.96937L12 11.4397L14.4694 8.96937C14.5391 8.89969 14.6218 8.84442 14.7128 8.8067C14.8039 8.76899 14.9015 8.74958 15 8.74958C15.0986 8.74958 15.1961 8.76899 15.2872 8.8067C15.3782 8.84442 15.4609 8.89969 15.5306 8.96937C15.6003 9.03906 15.6556 9.12178 15.6933 9.21283C15.731 9.30387 15.7504 9.40145 15.7504 9.5C15.7504 9.59855 15.731 9.69613 15.6933 9.78717C15.6556 9.87822 15.6003 9.96094 15.5306 10.0306L13.0603 12.5L15.5306 14.9694Z" fill="white" />
                                </svg>
                            </Box>

                        </Box>
                        <Box display={"flex"} flexDirection={"column"} width={"424px"} padding={"0px 32px 32px"} gap={"33px"}>
                            <Box display={"flex"} flexDirection={"column"} alignSelf={"stretch"} gap={"8px"}>
                                <CommonTypo size={24} lineHeight={28.8} fontWeight={700} color="var(--color-text-primary)">Save time with 100% accurate import</CommonTypo>
                                <CommonTypo size={16} lineHeight={22.4} fontWeight={400} color="var(--color-text-secondary)">We manually check and input your data. We’ll e-mail you once data is imported. It usually takes less than 1 day.</CommonTypo>
                            </Box>
                            <Box display={"flex"} flexDirection={"column"} alignSelf={"stretch"} gap={"8px"}>
                                <Box display={"flex"} flex={1} alignSelf={"stretch"} alignItems={"center"}>
                                    <NewButton
                                        buttonText="Continue with 100% accurate import"
                                        // onClick={() => updateRescueTime(selectedTab, checkedKeys, closemodal)}
                                        onClick={() => setSelectedVal("accurate")}
                                        extraSx={{
                                            background: "var(--color-new-main)",
                                            border: "none",
                                            height: "43px",
                                            flex: 1,
                                            marginBottom: "0px !important",
                                            "&:hover": {
                                                background: "var(--color-new-main)"
                                            },
                                            "&.MuiButton-outlined": {
                                                border: `none`,
                                            },
                                            "&.MuiButton-contained": {
                                                border: `none`,
                                            },
                                        }} fontSize={`16px`} fontWeight={600} typographySx={{
                                            fontSize: "16px !important",
                                            lineHeight: "16px !important",
                                            fontWeight: `600 !important`,
                                            letterSpacing: "2% important",
                                            whiteSpace: "nowrap"
                                        }}
                                    />
                                </Box>
                                <Box display={"flex"} flex={1} alignSelf={"stretch"} alignItems={"center"}>
                                    <NewButton
                                        buttonText="Continue with automated import"
                                        // onClick={() => updateRescueTime(selectedTab, checkedKeys, closemodal)}
                                        onClick={() => setSelectedVal("automated")}
                                        extraSx={{
                                            background: "var(--color-border)",
                                            border: "none",
                                            width: "102px",
                                            height: "43px",
                                            flex: 1,
                                            marginBottom: "0px !important",
                                            "&:hover": {
                                                background: "var(--color-border)"
                                            },
                                            "&.MuiButton-outlined": {
                                                border: `none`,
                                            },
                                            "&.MuiButton-contained": {
                                                border: `none`,
                                            },
                                        }} fontSize={`16px`} fontWeight={600} typographySx={{
                                            fontSize: "16px !important",
                                            lineHeight: "16px !important",
                                            fontWeight: `600 !important`,
                                            letterSpacing: "2% important",
                                            whiteSpace: "nowrap"
                                        }}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                ) : (
                    <Box sx={{
                        width: '480px',
                        height: '264px',
                        gap: '32px',
                        display: 'flex',
                        flexDirection: "column"
                    }}>

                        <Typography
                            variant="body1"
                            component="div"
                            sx={
                                {
                                    color: 'var(--color-black)',
                                    fontWeight: 700,
                                    fontSize: `${pxToRem(26)} !important`,
                                    lineHeight: "31.2px  !important",
                                    letterSpacing: '2%'
                                }
                            }
                        >
                            Import from a .xlsx file
                        </Typography>
                        <Box sx={{
                            width: '480px',
                            height: '201px',
                            borderRadius: '8px',
                            border: isDragging ? '2px dashed var(--primary-color)' : '2px dashed #DCE5E0',
                            display: 'flex',
                            flexDirection: "column",
                            alignItems: "center",
                            position: 'relative',
                            backgroundColor: isDragging ? 'rgb(17 115 74 / 20%)' : 'transparent',
                        }} onDragEnter={handleDragEnter}
                            onDragOver={(event) => event.preventDefault()}
                            onDragLeave={handleDragLeave}
                            onDrop={handleDrop}>

                            <Box position={"absolute"} top={"20%"} bottom={"20%"}>
                                <CSVFileIcon color={isDragging ? "#11734A" : "#DCE5E0"} />
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                alignItems: "center",
                                justifyContent: 'center',
                                position: 'absolute',
                                bottom: "10px"
                            }}>
                                <Typography
                                    variant="body1"
                                    component="span"
                                    sx={
                                        {
                                            color: 'var(--primary-color)',
                                            fontWeight: 400,
                                            fontSize: `${pxToRem(16)} !important`,
                                            lineHeight: `${pxToRem(20)}  !important`,
                                            textDecoration: 'underline'
                                        }
                                    }
                                >
                                    Open a CSV file
                                </Typography>
                                <Typography
                                    variant="body1"
                                    component="span"
                                    sx={
                                        {
                                            color: '#9BA19D',
                                            fontWeight: 400,
                                            fontSize: `${pxToRem(16)} !important`,
                                            lineHeight: `${pxToRem(20)}  !important`,
                                        }
                                    }
                                >
                                    , or drag and drop it here
                                </Typography>
                            </Box>
                            <input
                                type="file"
                                accept=".csv, .xlsx"
                                style={{
                                    position: 'absolute',
                                    width: '100%',
                                    height: '100%',
                                    opacity: 0,
                                    cursor: 'pointer',
                                }}
                                onChange={(event) => {
                                    const file = event.target.files[0];
                                    if (file) {
                                        handleUploadFile(file)
                                    }
                                }}
                            />
                        </Box>
                    </Box>
                )}

            {!apiCalled && !newDesign && <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
                <NewButton
                    buttonText="Cancel"
                    // onClick={props?.handleSubmit}
                    onClick={() => {
                        setIsDragging(false)
                        setUploadedFile(false)
                        setApiCalled(false)
                        setProgress(0)
                        sethasSuccess(false)
                        closeModal()
                    }}
                    borderRadius="6px"
                    padding="12px 36px 12px 36px"
                    borderColor="var(--color-new-main)"
                    fontWeight={500}
                    variant="outlined"
                />
            </Box>}
        </Box>
    </Dialog >
}

export default UploadFile