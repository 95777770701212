import React, { useReducer } from "react";
import { ITrackerAction, ITrackingTypeState, TrackingContextModal } from "../interfaces/trakingModel";
import { OutcomeStatus } from "../utils/staticDataJson";
import moment from "moment";

const defaultState: ITrackingTypeState = {
    showSnakbar: false,
    snakbarMsg: "",
    msgType: "success",
    snackbarversion: "v1",
    showLoader: false,
    isLastAlreadyLoaded: false,
    openStartTracking: false,
    isExpandable: localStorage.getItem("isExpandMode") === "true",
    isArchiveMode: false,
    openCategoryModal: false,
    showOutcome: false,
    showInfluence: false,
    showQues: false,
    outcomeIdeas: [],
    outcomeData: [],
    influenceIdeas: [],
    influenceIdeasData: [],
    reflectiveIdeas: [],
    reflectiveIdeasData: [],
    addOutcomeTracking: false,
    addInfluenceTracking: false,
    transformToInfluenceTracking: false,
    transformToOutcomeTracking: false,
    transformInInfluenceTracking: false,
    addQuesTracking: false,
    openStartModal: false,
    openStartModalInfluence: false,
    openStartModalQues: false,
    categoryId: "",
    textInput: OutcomeStatus,
    outComeValue: "",
    startDate: null,
    endDate: null,
    limitPopupVisible: false,
    limitPopuptype: null,
    influenceData: null,
    questionData: null,
    outcomeId: null,
    influenceId: null,
    weekday: new Map(),
    calendar: [],
    today: moment(),
    isFirst: "",
    openTrackingEventModal: false,
    outcomeIndex: null,
    selectedOption: null,
    showOutcomeInput: false,
    inputOutcomeValue: null,
    outcomeEditItem: null,
    isOutcomeEdit: false,
    outcomeOptionIndex: null,
    headerDate: null,
    tutorialHihjlightDots: true,
    expandedView: false/* localStorage.getItem("expandedView") === "true" */,
    dailyCompleteMesssage: null,
    openDayComplete: false,
    visibleDates: []
};

const reducer = (
    state: ITrackingTypeState,
    action: ITrackerAction
): ITrackingTypeState => {
    switch (action.type) {
        case "SET_SHOW_SNACKBAR":
            return {
                ...state,
                ...action.payload,
            };
        case "SET_TUTORIAL_HIGHLIGHT_DOTS":
            return {
                ...state,
                tutorialHihjlightDots: action.payload,
            };
        case "SET_EXPANDED_VIEW":
            return {
                ...state,
                expandedView: action.payload,
            }; 
        case "SET_DAY_COMPLETE":
            return {
                ...state,
                dailyCompleteMesssage: action.payload.message,
                openDayComplete: action.payload.open,
            }; 
        case "SET_SHOW_LOADER":
            return {
                ...state,
                showLoader: action.payload,
            };
        case "SET_OPEN_TRACKING":
            return {
                ...state,
                openStartTracking: action.payload,
            };
        case "SET_SIDEBAR_EXPAND":
            return {
                ...state,
                isExpandable: action.payload,
            };
        case "SET_ARCHIVE_MODE":
            return {
                ...state,
                // isArchiveMode: action.payload,
            };
        case "SET_OPEN_CATEGORY_MODAL":
            return {
                ...state,
                openCategoryModal: action.payload,
            };
        case "SET_TRACKING_STATE":
            return {
                ...state,
                ...action.payload,
            };
        case "SET_SHOW_OUTCOME":
            return {
                ...state,
                showOutcome: action.payload,
            };
        case "SET_SHOW_INFLUENCE":
            return {
                ...state,
                showInfluence: action.payload,
            };
        case "SET_SHOW_QUESTION":
            return {
                ...state,
                showQues: action.payload,
            };
        case "SET_OUTCOME_IDEAS":
            return {
                ...state,
                outcomeIdeas: action.payload.data,
                outcomeData: action.payload.ideas,
            };
        case "SET_INFLUENCE_IDEAS":
            return {
                ...state,
                influenceIdeas: action.payload.data,
                influenceIdeasData: action.payload.ideas,

            };
        case "SET_QUESTION_IDEAS":
            return {
                ...state,
                reflectiveIdeas: action.payload.data,
                reflectiveIdeasData: action.payload.ideas,
            };
        case "SET_VISIBLE_DATE": 
            return {
                ...state,
                visibleDates: [...state.visibleDates, action.payload]
            }
        default:
            return state;
    }
};

export const TrackingContext = React.createContext({} as TrackingContextModal);
type childernType = {
    children: React.ReactNode;
};
export const Provider: React.FC = (props: childernType) => {
    const [state, dispatch] = useReducer(reducer, defaultState);

    return (
        <TrackingContext.Provider value={{ state, dispatch }}>
            {props.children}
        </TrackingContext.Provider>
    );
};
