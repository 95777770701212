import React, { useState, useEffect, useContext, useCallback, useRef } from "react";
import {
    Button,
    Grid,
    TextField,
    Typography,
    SvgIcon,
    Dialog,
    Box,
    Popover,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import SnackBarComp from "../../components/snackBar";
import { makeStyles } from "@material-ui/core";
import palette from "../../theme/palette";
import shape from "../../theme/shape";
import BottomStepper from "./BottomStepper";
import PlanSelectionHeader from "./outcomeHeader";
import { pxToRem } from "../../theme/typography";
import useWidth from "../../utils/useWidth";
import { ButtonLoader } from "../../components/buttonLoader";
import CommonTypo from "../../components/CommonTypo";
import NewButton from "../../components/NewButton";

const hours = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23']
const minutes = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '50', '51', '52', '53', '54', '55', '56', '57', '58', '59']
interface LeftSideOutComeModal {
    outComeValue: any;
    setOutcomeValue: (outComeValue: string) => void;
    outcomeIdeas: any;
    setShowOutcome: (showOutcome: boolean) => void;
    showOutcome: boolean;
    setAddOutcomeTracking: (addOutcomeTracking: boolean) => void;
    setDesktopEnabled: (x: boolean) => void
    setTextInput: any;
    textInput: any;
    ispersonalMode?: boolean;
    handleCloseStartModal?: any;
    getOutcomeList?: any;
    categoryId?: any;
    setTransformToInfluenceTracking?: any;
    width?: any;
    setShowSnakbar?: any;
    setSnakbarMsg?: any;
    setMsgType?: any;
    startDate?: any
    endDate?: any
    onboarding?: boolean
    listOfImprovements?: any[]
    selectedPlans?: any[]
    setSelectedPlans?: any
    handleClickStep?: any
    selectedScheduleTime?: any
    setSelectedScheduleTime: (x: any) => void
    curretStep?: number
    secondselectedPlans?: any[]
    setSecondSelectedPlans?: any
    planOutcomes?: any
    setPlanOutcomes?: any
    planInfluences?: any
    setPlanInfluences?: any
    planReflectives?: any
    setPlanReflectives?: any
    planSelectedOutcomes?: any
    setSelectedPlanOutcomes?: any
    planSelectedInfluences?: any
    setSelectedPlanInfluences?: any
    planSelectedReflectives?: any
    setSelectedPlanReflectives?: any
    planStep?: any
    setPlanStep?: any
    buttonLoader?: boolean
}
const RootStyle = styled("div")(({ theme }) => ({
    padding: "40px 80px",
    display: 'flex',
    alignItems: "center",
    flexDirection: 'column',
    gap: '32px',
    marginTop: '-50px',
    [theme.breakpoints.up("xl")]: {
        padding: "60px",
    },
    [theme.breakpoints.down("lg")]: {
        padding: "40px",
    },
    [theme.breakpoints.down("md")]: {
        padding: "30px",
    },
    [theme.breakpoints.down("sm")]: {
        padding: "20px",
        marginTop: '0px',
    },
}));
const useStyles = makeStyles((theme) => ({
    registrationHeading: {
        color: palette.primary.newLight,
        fontSize: `${pxToRem(32)} !important`,
        fontWeight: 700,
        lineHeight: `${pxToRem(48)} !important`,
    },
    registrationHeadingInner: {
        color: `${palette.primary.main} !important`,
        fontSize: `${pxToRem(32)} !important`,
        fontWeight: 800,
        lineHeight: '145%',
    },
    registrationSubHeading: {
        color: `${palette.primary.light} !important`,
        fontSize: `${pxToRem(18)} !important`,
        textAlign: 'center',
        fontWeight: 400,
        lineHeight: '145% !important',
    },
    registrationSubHeading2: {
        color: `${palette.primary.light} !important`,
        fontSize: `${pxToRem(16)} !important`,
        textAlign: 'center',
        fontWeight: 400,
        lineHeight: '145% !important',
    },
    improvementTitle: {
        color: `${palette.primary.light} !important`,
        fontSize: `${pxToRem(18)} !important`,
        fontWeight: 700,
        lineHeight: '100% !important',
    },
    improvementSelectedTitle: {
        color: `${palette.primary.contrastText} !important`,
        fontSize: `${pxToRem(18)} !important`,
        fontWeight: 700,
        lineHeight: '100% !important',
    },
    pageTitle: {
        marginTop: "40px!important",
        marginBottom: "48px!important",
        [theme.breakpoints.down("lg")]: {
            marginTop: "30px!important",
            marginBottom: "38px!important",
        },
        [theme.breakpoints.down("md")]: {
            marginTop: "20px!important",
            marginBottom: "28px!important",
        },
        [theme.breakpoints.down("sm")]: {
            marginTop: "15px!important",
            marginBottom: "20px!important",
        },
    },
    needSomeIdeas: {
        fontSize: "16px",
        lineHeight: "16px",
        color: palette.primary.main,
        marginLeft: "8px",
        [theme.breakpoints.down("lg")]: {
            marginTop: "10px",
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: "14px",
            lineHeight: "14px",
        },
    },
    colorBlock: {
        cursor: "pointer",
        backgroundColor: palette.secondary.light,
        border: "1px solid #DCE6E0",
        borderRadius: shape.borderRadius,
        padding: "11px 16px",
        display: "flex",
        alignItems: "center",
        marginLeft: "16px",
        [theme.breakpoints.down("lg")]: {
            marginLeft: 0,
        },
    },
    spaceBetween: {
        [theme.breakpoints.down("lg")]: {
            marginTop: "10px",
            marginBottom: "10px",
        },
    },
    colorBlockText: {
        margin: 0,
        color: palette.primary.light,
        fontSize: `${pxToRem(16)} !important`,
        lineHeight: "16px",
        fontWeight: 500,
    },
    colorCode: {
        width: "24px",
        height: "24px",
        marginLeft: "10px",
        borderRadius: shape.borderRadiusSm,
    },
    outcomeTitle: {
        marginTop: "48px!important",
        marginBottom: "20px!important",
        [theme.breakpoints.down("lg")]: {
            marginTop: "40px!important",
        },
        [theme.breakpoints.down("md")]: {
            marginTop: "30px!important",
        },
        [theme.breakpoints.down("sm")]: {
            marginTop: "20px!important",
        },
    },
    ideasListItem: {
        backgroundColor: palette.common.white,
        borderRadius: shape.borderRadius,
        padding: "24px",
        fontSize: `${pxToRem(16)} !important`,
        lineHeight: "16px",
        color: palette.primary.light,
        fontWeight: 400,
        marginBottom: "12px",
        cursor: "pointer",
    },
    selectionBox: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexShrink: 0,
        border: "1px solid #FCFFFC",
        background: '#FCFFFC',
        cursor: "pointer",
        borderRadius: "50px",
        padding: "14px 25px 14px 25px",
        maxWidth: "100%",
        "&:hover": {
            border: "1px solid #D6F5C3",
        },
    },
    improvementTitleNew: {
        color: `${palette.primary.newLight} !important`,
        fontSize: '16px !important',
        fontWeight: 400,
        lineHeight: '100%',
    },
    improvementSelectedTitleNew: {
        color: `${palette.primary.light} !important`,
        fontSize: '16px !important',
        fontWeight: 400,
        lineHeight: '100%',
    },
}));
const LeftSideFinalImprovement = (props: LeftSideOutComeModal) => {
    const classes = useStyles();
    const selectRef = useRef(null);
    const [notificationsAllowed, setNotificationAllowed] = useState(false);
    const [showSnakbar, setShowSnakbar] = useState(false);
    const [snakbarMsg, setSnakbarMsg] = useState("");
    const [msgType, setMsgType] = useState("");
    const [selectedScheduleTime, setSelectedScheduleTime] = useState({
        hour: "00",
        minutes: "00"
    })
    const handleCloseSnakbar = () => {
        setShowSnakbar(false);
    };
    const { listOfImprovements } = props
    let nsames = []
    if (props.secondselectedPlans && props.secondselectedPlans.length) {
        for (let index = 0; index < props.secondselectedPlans.length; index++) {
            const element = props.secondselectedPlans[index];
            nsames.push(element.name)
        }
    }
    const width = useWidth()
    const isMobile = width < 768
    const bottomStepper = <BottomStepper
        isOutcome={true}
        isInfluence={false}
        isRefQues={false}
        isTracking={false}
        handleClickStep={props?.handleClickStep}
        curretStep={props?.curretStep}
        planStep={props?.planStep}
        nextDisabled={props?.planStep === 1 ? props.planSelectedOutcomes.length <= 0
            : props?.planStep === 2 ? props.planSelectedInfluences.length <= 0
                : props?.planStep === 3 ? props.planSelectedReflectives.length <= 0 : false}
        isMobile={isMobile}
        buttonLoader={props?.buttonLoader}
    />
    const isDisabled = props?.planStep === 1 ? props.planSelectedOutcomes.length <= 0
        : props?.planStep === 2 ? props.planSelectedInfluences.length <= 0
            : props?.planStep === 3 ? props.planSelectedReflectives.length <= 0 : false
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [isSelected, setIsSelected] = React.useState(null);
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    console.log("props?.planStep", props?.planStep);
    
    return <RootStyle
        // sx={!props.showOutcome ? { height: "100vh", overflowY: 'auto', paddingBottom: '100px !important' } : { height: "100vh", overflowY: 'auto', }}
        className={`login-right-part ${isMobile ? "right-side-improvement-common-bg-mobile height100" : "right-side-improvement-common-bg"}`}
        sx={isMobile ? {} : props?.planStep === 4 ? {
            marginTop: "-50px !important",
            padding: "40px 20px"
        } : props?.planStep === 3 ? {
            marginTop: "-70px !important"
        } : {}}
    >
        <SnackBarComp
            showSnakbar={showSnakbar}
            handleCloseSnakbar={handleCloseSnakbar}
            snakbarMsg={snakbarMsg}
            type={msgType ? msgType : "info"}
        />
        {isMobile && bottomStepper}
        <PlanSelectionHeader currentStep={props?.planStep} selectedPlan={props?.secondselectedPlans[0]} isMobile={isMobile} />
        <Typography
            variant="h1"
            component="h1"
            className={classes.registrationHeading}
            sx={isMobile ? {
                fontSize: `${pxToRem(22)} !important`,
                lineHeight: "120% !important",
                textAlign: "center !important",
                "&.MuiTypography-root": {
                    "@media  (max-width: 600px) and (min-width: 100px)": {
                        fontSize: `${pxToRem(22)} !important`,
                        lineHeight: "120% !important"
                    },
                }
            } : {
                fontWeight: "700 !important"
            }}
        >
            {props?.planStep === 1 ? "Outcomes" : props?.planStep === 2 ? "Influences" : props?.planStep === 3 ? "Reflective questions" : "Stay on Track with Daily Reminders"}

        </Typography>
        <Typography
            component="h1"
            className={classes.registrationSubHeading}
            sx={isMobile ? {
                fontSize: `${pxToRem(16)} !important`,
                lineHeight: "150% !important",
                textAlign: "center !important",
                "&.MuiTypography-root": {
                    "@media  (max-width: 600px) and (min-width: 100px)": {
                        fontSize: `${pxToRem(16)} !important`,
                        lineHeight: "150% !important"
                    },
                }
            } : {
                color: `${palette.primary.newLight} !important`,
                fontSize: `${pxToRem(18)} !important`,
                lineHeight: `${pxToRem(27)} !important`,
                textAlign: "center !important",
                fontWeight: "400 !important",
                width: "75%"
            }}
        >
            {props?.planStep === 1 ? "Outcomes are the metrics that matter the most to you, you want to understand well and change for the better."
                : props?.planStep === 2 ? "Influences are the metrics that you expect to impact your outcomes. Your energy (outcome) might be affected by the hours you sleept (influence)."
                    : props?.planStep === 3 ? "Reflective questions provide a comprehensive picture of outcomes and influences that can guide our growth decisions." : "To make the most of your tracking journey, we’d like to send you gentle reminders. These notifications will help you remember to log your progress, review your insights, and stay consistent with your goals."}


        </Typography>
        {props?.planStep !== 4 && <Typography
            component="h1"
            className={classes.registrationSubHeading2}
            sx={isMobile ? {
                fontSize: `${pxToRem(16)} !important`,
                lineHeight: "150% !important",
                textAlign: "center !important",
                "&.MuiTypography-root": {
                    "@media  (max-width: 600px) and (min-width: 100px)": {
                        fontSize: `${pxToRem(16)} !important`,
                        lineHeight: "150% !important"
                    },
                }
            } : {
                color: `${palette.primary.newLight} !important`,
                fontSize: `${pxToRem(16)} !important`,
                lineHeight: `${pxToRem(19)} !important`,
                textAlign: "center !important",
                fontWeight: "400 !important",
            }}
        >
            {props?.planStep === 1 ? <>Select up to <strong>two</strong> outcomes</>
                : props?.planStep === 2 ? <>Select up to <strong>five</strong> influences</>
                    : props?.planStep === 3 ? <>Select up to <strong>two</strong> reflective questions</> : ""}


        </Typography>}
        {isMobile ? <Grid
            container
            columns={16}
            rowSpacing={1} columnSpacing={{ xs: 2, sm: 2, md: 3 }}
            sx={isMobile ? {
                padding: "0px 24px",
                maxHeight: '100%',
                overflowY: 'auto',
                height: "calc(100vh - 30%)"
            } : {
                maxHeight: '27vh',
                overflowY: 'auto'
            }}
            className={isMobile ? "hide-scrollbar" : ""}
        >
              {props?.planStep === 4 && (
                <Box display={"flex"} flexDirection={"column"} gap={"16px"} alignSelf={"stretch"} width={"100%"}>
                    <Box display={"flex"} gap={"16px"} alignSelf={"stretch"} justifyContent={"space-between"}>
                        <Box display={"flex"} gap={"8px"} alignSelf={"stretch"} alignItems={"center"}>
                            <Box sx={{
                                background: "var(--color-new-main)",
                                height: "32px",
                                width: "32px",
                                borderRadius: "16px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center"
                            }}>
                                <CommonTypo fontWeight={600} size={21.33} lineHeight={32}>1</CommonTypo>
                            </Box>
                            <CommonTypo fontWeight={600} size={20} lineHeight={30} color="var(--sidebar-active-color)">Choose your perfect time</CommonTypo>
                        </Box>
                        <Box sx={{
                            background: "var(--white-background)",
                            width: "102px",
                            height: "43px",
                            borderRadius: "16px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        }}>
                            <Box sx={{ display: "flex", alignItems: 'center', gap: "4px" }} ref={selectRef}>
                                <Box sx={{
                                    width: '35px',
                                    height: '27px',
                                    border: '1px solid #EEEEEE',
                                    borderRadius: '8px',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    background: isSelected === "hour" ? "var(--color-border)" : "var(--white-background)"
                                }} onClick={() => {
                                    setAnchorEl(selectRef.current)
                                    setIsSelected('hour')
                                }}>
                                    <CommonTypo size={16} lineHeight={19.2} color="var(--color-text-primary)">{selectedScheduleTime.hour || ""}</CommonTypo>
                                </Box>
                                <Typography
                                    variant="caption"
                                    component="div"
                                    sx={{
                                        color: '#000000',
                                        fontWeight: 400,
                                        fontSize: '14px !important'
                                    }}
                                // className={`${classes.influenceCaption} ${classes.padding}`}
                                >
                                    :
                                </Typography>
                                <Box sx={{
                                    width: '35px',
                                    height: '27px',
                                    border: '1px solid #EEEEEE',
                                    borderRadius: '8px',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    background: isSelected === "minutes" ? "var(--color-border)" : "var(--white-background)"
                                }} onClick={() => {
                                    setAnchorEl(selectRef.current)
                                    setIsSelected('minutes')
                                }}>
                                    <CommonTypo size={16} lineHeight={19.2} color="var(--color-text-primary)">{selectedScheduleTime.minutes || ""}</CommonTypo>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box display={"flex"} gap={"16px"} alignSelf={"stretch"} justifyContent={"space-between"}>
                        <Box display={"flex"} gap={"8px"} alignSelf={"stretch"} alignItems={"center"}>
                            <Box sx={{
                                background: "var(--color-new-main)",
                                height: "32px",
                                width: "32px",
                                borderRadius: "16px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center"
                            }}>
                                <CommonTypo fontWeight={600} size={21.33} lineHeight={32}>2</CommonTypo>
                            </Box>
                            <Box display={"flex"} flexDirection={"column"} gap={"0px"} alignSelf={"stretch"}>
                                <CommonTypo fontWeight={600} size={20} lineHeight={30} color="var(--sidebar-active-color)">Give us a permission to send you notifications</CommonTypo>
                                <CommonTypo fontWeight={400} size={12} lineHeight={18} color="var(--sidebar-active-color)">Your device will prompt you to allow notifications after you press the button</CommonTypo>

                            </Box>

                        </Box>
                        <Box>
                            {notificationsAllowed ? (
                                <Box sx={{
                                    width: "102px",
                                    height: "43px",
                                    padding: "16px",
                                    gap: "8px",
                                    borderRadius: "20px",
                                    background: "#ADE888",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center"
                                }}>
                                    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3 7.5L7 11.5L14 4.5" stroke="#3E4240" stroke-width="2" />
                                    </svg>

                                </Box>
                            ) : (
                                <NewButton buttonText="Allow" borderRadius={"20px !important"} padding="16px" extraSx={{
                                    background: "var(--color-border)",
                                    border: "none",
                                    width: "102px",
                                    height: "43px",
                                    marginBottom: "0px !important",
                                    "&:hover": {
                                        background: "var(--color-border)"
                                    },
                                    "&.MuiButton-outlined": {
                                        border: `none`,
                                    },
                                    "&.MuiButton-contained": {
                                        border: `none`,
                                    },
                                }} fontSize={`16px`} fontWeight={600} typographySx={{
                                    fontSize: "16px !important",
                                    lineHeight: "16px !important",
                                    fontWeight: `600 !important`,
                                    letterSpacing: "2% important"
                                }} onClick={() => {
                                    if ('Notification' in window) {
                                        Notification.requestPermission()
                                            .then(permission => {
                                                if (permission === 'granted') {
                                                    console.log('Notification permission granted.');
                                                    setNotificationAllowed(true)
                                                    props.setDesktopEnabled(true)
                                                    // Optionally, display a test notification
                                                    new Notification('Thank you for enabling notifications!');
                                                } else {
                                                    setShowSnakbar(true)
                                                    props.setDesktopEnabled(false)
                                                    setSnakbarMsg("Notifications permission has been blocked. Please reset permission")
                                                    setMsgType("error")
                                                    console.log('Notification permission denied.');
                                                }
                                            })
                                            .catch(error => {
                                                setShowSnakbar(true)
                                                props.setDesktopEnabled(false)
                                                setSnakbarMsg("Notifications permission has been blocked. Please reset permission")
                                                setMsgType("error")
                                                console.error('Error requesting notification permission:', error);
                                            });
                                    } else {
                                        console.log('This browser does not support notifications.');
                                    }
                                }} />
                            )}

                        </Box>
                    </Box>
                    <Popover
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        sx={{
                            '& .MuiPaper-root': {
                                background: "#FFFFFF",
                                boxShadow: "0px 0px 64px 0px #190D1A1A",
                                borderRadius: "12px",
                                width: "102px",
                                marginTop: "10px"
                            }
                        }}
                    >
                        <Box sx={{
                            padding: "0px",
                            borderRadius: "8px",
                            height: "198px",
                            overflowY: "auto",
                            display: "flex",
                            flexDirection: "column",

                            // justifyContent: "flex-end"
                        }}>
                            {(isSelected === "minutes" ? minutes : hours).map(it => <Box key={it} display={"flex"} alignItems={"center"} justifyContent={isSelected === "minutes" ? "flex-end" : "flex-start"} width={"100%"} borderRadius={"8px"} padding={"4px 8px"} sx={{
                                cursor: "pointer"
                            }} onClick={() => {
                                setSelectedScheduleTime({
                                    ...selectedScheduleTime,
                                    [isSelected]: it
                                })
                                setIsSelected(null)
                                setAnchorEl(null)
                            }}>
                                <CommonTypo size={12} color="var(--color-text-primary)" lineHeight={14.4}>{it}</CommonTypo>
                            </Box>)}
                        </Box>
                    </Popover>
                </Box>
            )}
            {(props?.planStep === 1 ? props.planOutcomes : props?.planStep === 2 ? props?.planInfluences : props?.planStep === 3 ? props?.planReflectives : []).map(it => {
                let fundData = []
                let found = undefined
                if (props?.planStep === 1) {
                    fundData = [...(props.planSelectedOutcomes || [])]
                    found = fundData.find(ite => ite.outcomeId === it.outcomeId)
                }
                if (props?.planStep === 2) {
                    fundData = [...(props.planSelectedInfluences || [])]
                    found = fundData.find(ite => ite.influenceId === it.influenceId)
                }
                if (props?.planStep === 3) {
                    fundData = [...(props.planSelectedReflectives || [])]
                    found = fundData.find(ite => ite.reflectiveId === it.reflectiveId)
                }

                return <Grid item xs={isMobile ? 16 : [1].includes(props?.planStep) ? 8 : [2].includes(props?.planStep) ? 5.33 : 16} >
                    <Box sx={{
                        display: 'flex',
                        padding: '18px 21px 17px 20px',
                        justifyContent: isMobile ? 'flex-start' : 'center',
                        alignItems: 'flex-start',
                        flexShrink: 0,
                        borderRadius: '5px',
                        // border: '2px solid #BADACA',
                        // background: found ? '#11734A' : 'rgba(252, 255, 250, 0.50)',

                        border: found ? "2px solid " + "#D6F5C3" + " !important" : "2px solid #BADACA",
                        background: found ? `${"#D6F5C3"} !important` : 'rgba(252, 255, 250, 0.50)',
                        cursor: "pointer"
                    }} onClick={() => {
                        if (found) {
                            if (props?.planStep === 1) {
                                fundData = fundData.filter(ite => ite.outcomeId !== it.outcomeId)
                                props?.setSelectedPlanOutcomes(fundData)
                            }
                            if (props?.planStep === 2) {
                                fundData = fundData.filter(ite => ite.influenceId !== it.influenceId)
                                props?.setSelectedPlanInfluences(fundData)
                            }
                            if (props?.planStep === 3) {
                                fundData = fundData.filter(ite => ite.reflectiveId !== it.reflectiveId)
                                props?.setSelectedPlanReflectives(fundData)
                            }

                        } else {
                            if (props?.planStep === 1 && fundData.length < 2) {
                                fundData.push(it)
                                props?.setSelectedPlanOutcomes(fundData)
                            }
                            if (props?.planStep === 2 && fundData.length < 5) {
                                fundData.push(it)
                                props?.setSelectedPlanInfluences(fundData)
                            }
                            if (props?.planStep === 3 && fundData.length < 2) {
                                fundData.push(it)
                                props?.setSelectedPlanReflectives(fundData)
                            }
                        }
                        // props?.setSecondSelectedPlans([it])
                    }}>
                        <Typography
                            component="span"
                            className={found ? classes.improvementSelectedTitleNew : classes.improvementTitleNew}
                            sx={isMobile ? {
                                fontSize: `${pxToRem(20)} !important`,
                                lineHeight: "150% !important",
                                // textAlign: "center !important",
                                fontWeight: /* found ? `700 !important` : */ `400 !important`,
                                "&.MuiTypography-root": {
                                    "@media  (max-width: 600px) and (min-width: 100px)": {
                                        fontSize: `${pxToRem(20)} !important`,
                                        lineHeight: "150% !important"
                                    },
                                }
                            } : {
                                fontWeight: found ? `700 !important` : `400 !important`,
                                textAlign: 'center'
                            }}
                        >
                            {it.name || it.question}
                        </Typography>

                    </Box>
                </Grid>
            })}
        </Grid> : <Box display={"flex"} alignItems={"center"} className="hide-scrollbar" justifyContent={props?.planStep === 4 ? "flex-start" : props?.planStep === 3 ? "center" : "center"} flexWrap={"wrap"} sx={{
            maxHeight: '38vh',
            overflowY: 'auto',
            gap: "12px",
            width: "85%"
        }}>
            {props?.planStep === 4 && (
                <Box display={"flex"} flexDirection={"column"} gap={"16px"} alignSelf={"stretch"} width={"100%"}>
                    <Box display={"flex"} gap={"16px"} alignSelf={"stretch"} justifyContent={"space-between"}>
                        <Box display={"flex"} gap={"8px"} alignSelf={"stretch"} alignItems={"center"}>
                            <Box sx={{
                                background: "var(--color-new-main)",
                                height: "32px",
                                width: "32px",
                                borderRadius: "16px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center"
                            }}>
                                <CommonTypo fontWeight={600} size={21.33} lineHeight={32}>1</CommonTypo>
                            </Box>
                            <CommonTypo fontWeight={600} size={20} lineHeight={30} color="var(--sidebar-active-color)">Choose your perfect time</CommonTypo>
                        </Box>
                        <Box sx={{
                            background: "var(--white-background)",
                            width: "102px",
                            height: "43px",
                            borderRadius: "16px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        }}>
                            <Box sx={{ display: "flex", alignItems: 'center', gap: "4px" }} ref={selectRef}>
                                <Box sx={{
                                    width: '35px',
                                    height: '27px',
                                    border: '1px solid #EEEEEE',
                                    borderRadius: '8px',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    background: isSelected === "hour" ? "var(--color-border)" : "var(--white-background)"
                                }} onClick={() => {
                                    setAnchorEl(selectRef.current)
                                    setIsSelected('hour')
                                }}>
                                    <CommonTypo size={16} lineHeight={19.2} color="var(--color-text-primary)">{selectedScheduleTime.hour || ""}</CommonTypo>
                                </Box>
                                <Typography
                                    variant="caption"
                                    component="div"
                                    sx={{
                                        color: '#000000',
                                        fontWeight: 400,
                                        fontSize: '14px !important'
                                    }}
                                // className={`${classes.influenceCaption} ${classes.padding}`}
                                >
                                    :
                                </Typography>
                                <Box sx={{
                                    width: '35px',
                                    height: '27px',
                                    border: '1px solid #EEEEEE',
                                    borderRadius: '8px',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    background: isSelected === "minutes" ? "var(--color-border)" : "var(--white-background)"
                                }} onClick={() => {
                                    setAnchorEl(selectRef.current)
                                    setIsSelected('minutes')
                                }}>
                                    <CommonTypo size={16} lineHeight={19.2} color="var(--color-text-primary)">{selectedScheduleTime.minutes || ""}</CommonTypo>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box display={"flex"} gap={"16px"} alignSelf={"stretch"} justifyContent={"space-between"}>
                        <Box display={"flex"} gap={"8px"} alignSelf={"stretch"} alignItems={"center"}>
                            <Box sx={{
                                background: "var(--color-new-main)",
                                height: "32px",
                                width: "32px",
                                borderRadius: "16px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center"
                            }}>
                                <CommonTypo fontWeight={600} size={21.33} lineHeight={32}>2</CommonTypo>
                            </Box>
                            <Box display={"flex"} flexDirection={"column"} gap={"0px"} alignSelf={"stretch"}>
                                <CommonTypo fontWeight={600} size={20} lineHeight={30} color="var(--sidebar-active-color)">Give us a permission to send you notifications</CommonTypo>
                                <CommonTypo fontWeight={400} size={12} lineHeight={18} color="var(--sidebar-active-color)">Your device will prompt you to allow notifications after you press the button</CommonTypo>

                            </Box>

                        </Box>
                        <Box>
                            {notificationsAllowed ? (
                                <Box sx={{
                                    width: "102px",
                                    height: "43px",
                                    padding: "16px",
                                    gap: "8px",
                                    borderRadius: "20px",
                                    background: "#ADE888",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center"
                                }}>
                                    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3 7.5L7 11.5L14 4.5" stroke="#3E4240" stroke-width="2" />
                                    </svg>

                                </Box>
                            ) : (
                                <NewButton buttonText="Allow" borderRadius={"20px !important"} padding="16px" extraSx={{
                                    background: "var(--color-border)",
                                    border: "none",
                                    width: "102px",
                                    height: "43px",
                                    marginBottom: "0px !important",
                                    "&:hover": {
                                        background: "var(--color-border)"
                                    },
                                    "&.MuiButton-outlined": {
                                        border: `none`,
                                    },
                                    "&.MuiButton-contained": {
                                        border: `none`,
                                    },
                                }} fontSize={`16px`} fontWeight={600} typographySx={{
                                    fontSize: "16px !important",
                                    lineHeight: "16px !important",
                                    fontWeight: `600 !important`,
                                    letterSpacing: "2% important"
                                }} onClick={() => {
                                    if ('Notification' in window) {
                                        Notification.requestPermission()
                                            .then(permission => {
                                                if (permission === 'granted') {
                                                    console.log('Notification permission granted.');
                                                    setNotificationAllowed(true)
                                                    props.setDesktopEnabled(true)
                                                    // Optionally, display a test notification
                                                    new Notification('Thank you for enabling notifications!');
                                                } else {
                                                    setShowSnakbar(true)
                                                    props.setDesktopEnabled(false)
                                                    setSnakbarMsg("Notifications permission has been blocked. Please reset permission")
                                                    setMsgType("error")
                                                    console.log('Notification permission denied.');
                                                }
                                            })
                                            .catch(error => {
                                                setShowSnakbar(true)
                                                props.setDesktopEnabled(false)
                                                setSnakbarMsg("Notifications permission has been blocked. Please reset permission")
                                                setMsgType("error")
                                                console.error('Error requesting notification permission:', error);
                                            });
                                    } else {
                                        console.log('This browser does not support notifications.');
                                    }
                                }} />
                            )}

                        </Box>
                    </Box>
                    <Popover
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        sx={{
                            '& .MuiPaper-root': {
                                background: "#FFFFFF",
                                boxShadow: "0px 0px 64px 0px #190D1A1A",
                                borderRadius: "12px",
                                width: "102px",
                                marginTop: "10px"
                            }
                        }}
                    >
                        <Box sx={{
                            padding: "0px",
                            borderRadius: "8px",
                            height: "198px",
                            overflowY: "auto",
                            display: "flex",
                            flexDirection: "column",

                            // justifyContent: "flex-end"
                        }}>
                            {(isSelected === "minutes" ? minutes : hours).map(it => <Box key={it} display={"flex"} alignItems={"center"} justifyContent={isSelected === "minutes" ? "flex-end" : "flex-start"} width={"100%"} borderRadius={"8px"} padding={"4px 8px"} sx={{
                                cursor: "pointer"
                            }} onClick={() => {
                                setSelectedScheduleTime({
                                    ...selectedScheduleTime,
                                    [isSelected]: it
                                })
                                setIsSelected(null)
                                setAnchorEl(null)
                            }}>
                                <CommonTypo size={12} color="var(--color-text-primary)" lineHeight={14.4}>{it}</CommonTypo>
                            </Box>)}
                        </Box>
                    </Popover>
                </Box>
            )}
            {(props?.planStep === 1 ? props.planOutcomes : props?.planStep === 2 ? props?.planInfluences : props?.planStep === 3 ? props?.planReflectives : []).map(it => {
                let fundData = []
                let found = undefined
                if (props?.planStep === 1) {
                    fundData = [...(props.planSelectedOutcomes || [])]
                    found = fundData.find(ite => ite.outcomeId === it.outcomeId)
                }
                if (props?.planStep === 2) {
                    fundData = [...(props.planSelectedInfluences || [])]
                    found = fundData.find(ite => ite.influenceId === it.influenceId)
                }
                if (props?.planStep === 3) {
                    fundData = [...(props.planSelectedReflectives || [])]
                    found = fundData.find(ite => ite.reflectiveId === it.reflectiveId)
                }
                return <Box className={classes.selectionBox} sx={{
                    border: found ? "1px solid " + "#D6F5C3" + " !important" : "1px solid #FCFFFC",
                    background: found ? `${"#D6F5C3"} !important` : 'rgba(252, 255, 250, 0.50)',
                }} onClick={() => {
                    if (found) {
                        if (props?.planStep === 1) {
                            fundData = fundData.filter(ite => ite.outcomeId !== it.outcomeId)
                            props?.setSelectedPlanOutcomes(fundData)
                        }
                        if (props?.planStep === 2) {
                            fundData = fundData.filter(ite => ite.influenceId !== it.influenceId)
                            props?.setSelectedPlanInfluences(fundData)
                        }
                        if (props?.planStep === 3) {
                            fundData = fundData.filter(ite => ite.reflectiveId !== it.reflectiveId)
                            props?.setSelectedPlanReflectives(fundData)
                        }

                    } else {
                        if (props?.planStep === 1 && fundData.length < 2) {
                            fundData.push(it)
                            props?.setSelectedPlanOutcomes(fundData)
                        }
                        if (props?.planStep === 2 && fundData.length < 5) {
                            fundData.push(it)
                            props?.setSelectedPlanInfluences(fundData)
                        }
                        if (props?.planStep === 3 && fundData.length < 2) {
                            fundData.push(it)
                            props?.setSelectedPlanReflectives(fundData)
                        }
                    }
                    // props?.setSecondSelectedPlans([it])
                }}>
                    <Typography
                        component="span"
                        className={found ? classes.improvementSelectedTitleNew : classes.improvementTitleNew}
                        sx={isMobile ? {
                            fontSize: `${pxToRem(20)} !important`,
                            lineHeight: "normal !important",
                            textAlign: "center !important",
                            // fontWeight: `400 !important`,
                            fontWeight: found ? `700 !important` : `400 !important`,
                            "&.MuiTypography-root": {
                                "@media  (max-width: 600px) and (min-width: 100px)": {
                                    fontSize: `${pxToRem(20)} !important`,
                                    lineHeight: "normal !important"
                                },
                            }
                        } : {
                            fontWeight: found ? `400 !important` : `400 !important`
                        }}
                    >
                        {it.name || it.question}
                    </Typography>
                </Box>
            })}
        </Box>}
        {!isMobile && bottomStepper}
        {isMobile && !props?.buttonLoader && <Button
            variant="contained"
            className="new-button"
            fullWidth
            sx={{ mb: `0px !important`, height: "60px" }}
            disabled={isDisabled}
            onClick={() => {
                props?.handleClickStep(props.curretStep + 1)
            }}
        >
            <Typography
                variant="h1"
                component="span"
                color={isDisabled ? "#A0A0A1" : "#124743"}
                sx={{
                    fontSize: `${pxToRem(16)} !important`,
                    lineHeight: "normal !important",
                    textAlign: "center !important",
                    fontWeight: `400 !important`,
                    "&.MuiTypography-root": {
                        "@media  (max-width: 600px) and (min-width: 100px)": {
                            fontSize: `${pxToRem(16)} !important`,
                            lineHeight: "normal !important"
                        },
                    }
                }}
            >
                Continue

            </Typography>
        </Button>}
        {isMobile && props?.buttonLoader && <Button
            variant="contained"
            className="new-button"
            fullWidth
            sx={{ mb: `0px !important`, height: "60px" }}
            disabled={props?.planStep === 1 ? props.planSelectedOutcomes.length <= 0
                : props?.planStep === 2 ? props.planSelectedInfluences.length <= 0
                    : props?.planStep === 3 ? props.planSelectedReflectives.length <= 0 : false}
            onClick={() => {

            }}
        >
            <ButtonLoader />
        </Button>}
    </RootStyle>
}

export default LeftSideFinalImprovement