import { Box, TextField } from "@mui/material"
import React from "react"
import CommonTypo from "../../../components/CommonTypo"
import { Input } from "../../../components/inputGroup"
import CloseButton from "../../../components/CloseButton"
import SocialReportSchedules from "./SocialReportSchedules"

const SocialScheduleReport = ({
    setSideModalVisible,
    setSelectedFocus,
    isSideModalVisible,
    isSelectedFocus,
    selectedNodes,
    allContacts,
    viewSchedule,
    allDefaultStates,
    viewSceduleReports,
    viewSocialReports,
    classes,
    editRecord,
    setEditRecord,
    setShowSnakbar,
    setMsgType,
    setSnakbarMsg
}) => {
    let contactNames = []
    console.log("selectedNodes ?????", allContacts, selectedNodes);
    
    if (selectedNodes && selectedNodes.length) {
        const fdun = allContacts.filter(it => selectedNodes.includes(it._id))
        console.log("fdun", fdun);
        
        if (fdun && fdun.length) {
            for (let index = 0; index < fdun.length; index++) {
                const element = fdun[index];
                if (element.contacts && element.contacts.length) {
                    const filteredData = element.contacts.filter(it => selectedNodes.includes(it._id))
                    contactNames.push(...filteredData)
                }
            }
        }
        if(!fdun.length) {
            const found = allContacts.filter(it => ["all"].includes(it._id))
            if (found && found.length) {
                for (let index = 0; index < found.length; index++) {
                    const element = found[index];
                    console.log("selectedNodes element", element);
                    
                    if (element.contacts && element.contacts.length) {
                        const filteredData = element.contacts.filter(it => selectedNodes.includes(it._id))
                        contactNames.push(...filteredData)
                    }
                }
            }
        }
    }
    console.log("selectedNodes", selectedNodes, allContacts, contactNames)
    // Step 1: Take the first 2 elements as they are.
    const firstTwo = contactNames.slice(0, 2);

    // Step 2: For the remaining elements, increment `a` by 2.
    const remaining = contactNames.slice(2);
    return <>
        <Box
            display={"flex"}
            padding={"0px"}
            height={"100%"}
            flexDirection={"column"}
            gap={"16px"}
            alignItems={"flex-start"}
            alignSelf={"stretch"}
            justifyContent={"flex-start"}
            sx={{
                width: "360px",
                maxHeight: "650px",
                // minHeight: "310px",
                height: "auto",
                overflowY: "auto"
            }}>
            <SocialReportSchedules
                socialReportsScedules={[]}
                allContacts={allContacts}
                allDefaultStates={allDefaultStates}
                viewSceduleReports={viewSceduleReports}
                setSelectedFocus={setSelectedFocus}
                setSideModalVisible={setSideModalVisible}
                viewSocialReports={viewSocialReports}
                classes={classes}
                editRecord={editRecord}
                setEditRecord={setEditRecord}
                setShowSnakbar={setShowSnakbar}
                setSnakbarMsg={setSnakbarMsg}
                setMsgType={setMsgType}
            />
        </Box>
    </>
}

export default SocialScheduleReport