import Popover from "@mui/material/Popover";
import React, { useEffect, useState } from "react";
import palette from "../../theme/palette";
import { Button, TextField, Box, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import CheckIcon from "@mui/icons-material/Check";
import { pxToRem } from "../../theme/typography";
import NewButton from "../../components/NewButton";
import Picker from "react-mobile-picker-scroll";
import moment from "moment";
const useStyles = makeStyles((theme) => ({
  popover: {
    "& .MuiPaper-root": {
      color: palette.primary.light,
      padding: "24px 16px",
      backgroundColor: "#EBF2F1",
      border: "1px solid #DCE6E0",
      borderRadius: "12px",
    },
  },
  valueField: {
    "& .MuiOutlinedInput-root": {
      display: "flex",
      padding: "12px",
      alignItems: "baseline",
      gap: "12px",
      flex: "1 0 0",
      borderRadius: "12px !important",
      border: "1px solid #EDEDED",
      background: "#FFF",
    },
    "& .MuiInputBase-input": {
      padding: "0 !important",
    },
  },
  decField: {
    "& .MuiOutlinedInput-root": {
      display: "flex",
      padding: "12px",
      // alignItems: 'baseline',
      gap: "12px",
      flex: "1 0 0",
      borderRadius: "12px !important",
      border: "1px solid #EDEDED",
      background: "#FFF",
    },
  },
  colorDiv: {
    borderRadius: "8px",
    // marginRight: "6px",
    width: "20px",
    height: "20px",
  },
  deleteText: {
    color: "#F17F79",
    fontSize: "12px !important",
    cursor: "pointer",
  },
  closeBtn: {
    width: "16px",
    height: "16px",
    position: "absolute",
    right: "20px",
    top: "20px",
    cursor: "pointer",
  },
  groupSection: {
    display: "flex",
    width: "220px",
    padding: "20px",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "12px",
    borderBottom: "1px solid #F3F3F3",
  },
  frame4466: {
    display: "flex",
    padding: "20px",
    alignItems: "flex-start",
    gap: "8px",
    alignSelf: "stretch",
  },
}));
const optionGroups = {
  hour: [
    "00",
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
  ],
  minutus: [
    "00",
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "29",
    "30",
    "31",
    "32",
    "33",
    "34",
    "35",
    "36",
    "37",
    "38",
    "39",
    "40",
    "41",
    "42",
    "43",
    "44",
    "45",
    "46",
    "47",
    "48",
    "49",
    "50",
    "51",
    "52",
    "53",
    "54",
    "55",
    "56",
    "57",
    "58",
    "59",
  ],
  // AMPM: ["AM", "PM"],
};
const AddPopOver = (props) => {
  const classes = useStyles();
  const handleAddValue = (e) => {
    const data = {
      id: props?.editableNode?.id || new Date(),
      value: props.neutralScaler === "NumericValue" ? props.value : parseInt(props.value),
      text: props.desc,
      colorCode: props.color,
      answerId: props?.editableNode?.answerId || "",
    };
    props.setMatchValue(false);
    if (Boolean(props.value) && Boolean(props.desc) && Boolean(props.color)) {
      if (props?.openPopupinEditMode?.status) {
        const prevTextValues = [...props?.textFieldValues];
        const foundIndex = [...prevTextValues]?.findIndex((it) =>
          props?.editableNode?.answerId
            ? it.answerId === data?.answerId
            : it.id === data.id
        );
        if (foundIndex > -1) {
          const newCopy = [...prevTextValues];
          newCopy.splice(foundIndex, 1);
          const checkValueExist = newCopy.filter((item) => {
            return item.value == data.value || item.text == data.text;
          });
          if (checkValueExist.length > 0) {
            props.setMatchValue(true);
          } else {
            prevTextValues[foundIndex] = data;
            props.setTextFieldValues(prevTextValues);
            props.setValue("");
            props.setDesc("");
            props.setColor("#E57C73");
            props.handleClose();
          }
        } else {
          props.setValue("");
          props.setDesc("");
          props.setColor("#E57C73");
          props.handleClose();
        }
      } else {
        if (props.nodeCount === 0) {
          let [firstElement, secondElement] = props.textFieldValues;
          props.setTextFieldValues([data, secondElement]);
          props.setNodeCount((prev) => prev + 1);
          props.setValue("");
          props.setDesc("");
          props.setColor("#E57C73");
          props.handleClose();
        } else if (props.nodeCount === 1) {
          let [firstElement, secondElement] = props.textFieldValues;
          const checkValueExist = props.textFieldValues.filter((item) => {
            return item.value == data.value || item.text == data.text;
          });
          if (checkValueExist.length > 0) {
            props.setMatchValue(true);
          } else {
            props.setTextFieldValues([firstElement, data]);
            props.setNodeCount((prev) => prev + 1);
            props.setValue("");
            props.setDesc("");
            props.setColor("#E57C73");
            props.handleClose();
          }
        } else {
          const checkValueExist = props.textFieldValues.filter((item) => {
            return item.value == data.value || item.text == data.text;
          });
          if (checkValueExist.length > 0) {
            props.setMatchValue(true);
          } else {
            props.setTextFieldValues((preValue) => [...preValue, data]);
            props.setNodeCount((prev) => prev + 1);
            props.setValue("");
            props.setDesc("");
            props.setColor("#E57C73");
            props.handleClose();
          }
        }
        // if (props.textFieldValues[0]?.temp || props.textFieldValues[1]?.temp) {
        //   props.setTextFieldValues([data]);
        // } else {
        //   props.setTextFieldValues(preValue => [...preValue, data]);  // }
      }
    } else {
      props.setMatchValue(true);
    }
  };

  const handleColorCheck = (color: string) => {
    let arr = [...props.colorPalette];
    arr.forEach((obj) => {
      if (obj.color === color) {
        obj.isSelected = true;
      } else {
        obj.isSelected = false;
      }
    });

    props.setColorPalette(arr);
  };

  useEffect(() => {
    // if(props?.openPopupinEditMode?.status){
    //   if(props?.color){
    handleColorCheck(props?.color);
    // }
    // }
  }, [props?.openPopupinEditMode, props?.color]);

  const handleDeleteValue = () => {
    if (
      props.textFieldValues[0].temp === undefined ||
      props.textFieldValues[1].temp === undefined
    ) {
      props.deleteCustomeScaleValue(props?.editableNode?.id);
    }
    props.handleClose();
  };

  const handleValueChange = (e) => {
    props.setValue(e.target.value);
    props.matchValue && props.setMatchValue(false);
  };

  const handledeskChange = (e) => {
    props.setDesc(e.target.value);
    props.matchValue && props.setMatchValue(false);
  };
  
  const [hour, setHour] = useState(moment(new Date()).format("HH"));
  const [minute, setMinute] = useState(moment(new Date()).format("mm"));
  const [endTimeValuegroup, setEndTimeValuegroup] = useState({
    hour: moment(new Date()).format("HH"),
    minutus: moment(new Date()).format("mm"),
    AMPM: moment.utc(new Date()).format("A"),
  });
  useEffect(() => {
      if(props.value && props.neutralScaler === "NumericValue") {
        const time = props.value?.split(":");
        setHour(time[0] ?? "");
        setMinute(time[1] ?? "");
        setEndTimeValuegroup({
          hour: time[0] ?? moment(new Date()).format("HH"),
          minutus: time[1] ?? moment(new Date()).format("mm"),
          AMPM: moment.utc(new Date()).format("A"),
        });
      }
      if(!props.value  && props.neutralScaler === "NumericValue") {
        handleValueChange({
          target: {
            value: `${hour}:${minute}`
          }
        })
      }
  }, [props.value])
  return (
    <Popover
      open={props.open}
      // open={true}
      anchorEl={props.anchorEl}
      onClose={props.handleClose}
      // anchorReference="anchorPosition"
      // anchorPosition={{ top: 315, left: 60 }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      sx={{
        padding: "0",
        "& .MuiPaper-root": {
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          borderRadius: "12px",
          border: "1px solid #F3F3F3",
          background: "#FFF",
          boxShadow: "0px 0px 94px 0px rgba(0, 0, 0, 0.13)",
          padding: "0",
        },
      }}
      // transformOrigin={{
      //   vertical: "top",
      //   horizontal: "left",
      // }}
      // elevation={0}
      className={classes.popover}
    >
      <div className={classes.closeBtn} onClick={props.handleClose}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M12 12L8.00001 8.00001M8.00001 8.00001L4 4M8.00001 8.00001L12 4M8.00001 8.00001L4 12"
            stroke="#999999"
            strokeWidth="1.33333"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
      <div className={classes.groupSection}>
        {props.matchValue && Boolean(props.value) && Boolean(props.desc) ? (
          <Typography
            variant="body2"
            sx={{ color: "#f17f79", fontSize: "16px", marginBottom: "10px" }}
          >
            Value already exist
          </Typography>
        ) : (
          props.matchValue && (
            <Typography
              variant="body2"
              sx={{ color: "#f17f79", fontSize: "16px", marginBottom: "10px" }}
            >
              All fields are required
            </Typography>
          )
        )}
        <Typography variant="subtitle1">Add value</Typography>
        <div
          style={{
            display: "flex",
            alignItems: "flex-end",
            gap: "8px",
            alignSelf: "stretch",
          }}
        >
          {props.neutralScaler === "NumericValue" ? (
            <Picker
              optionGroups={optionGroups}
              valueGroups={endTimeValuegroup}
              onChange={(name, value) => {
                setEndTimeValuegroup({
                  ...endTimeValuegroup,
                  [name]: value,
                });
                if (name === "hour") {
                  setHour(value);
                }
                if (name === "minutus") {
                  setMinute(value);
                }
                handleValueChange({
                  target: {
                    value: `${hour}:${minute}`
                  }
                })
              }}
            />
          ) : <TextField
            type={"number"}
            className={classes.valueField}
            value={props.value}
            onChange={(e) => handleValueChange(e)}
          // onChange={(e) => !props?.openPopupinEditMode?.status && props.setValue(e.target.value) props.setMatchValue(false)}
          />}


        </div>
      </div>
      <div className={classes.groupSection}>
        <Typography variant="subtitle1">Add description</Typography>
        <TextField
          type={"textarea"}
          className={classes.decField}
          value={props.desc}
          onChange={(e) => handledeskChange(e)}
          multiline
          // rows={2}
          minRows={2}
          maxRows={4}
        // onChange={(e) => !props?.openPopupinEditMode?.status && props.setDesc(e.target.value)}
        />
      </div>
      <div className={classes.groupSection}>
        <Typography variant="subtitle1">Select color</Typography>

        <div
          style={{
            display: "flex",
            padding: "12px",
            alignItems: "flex-start",
            gap: "12px",
            alignSelf: "stretch",
          }}
        >
          {props.colorPalette.map((color) => (
            <Box
              key={color.color}
              sx={{
                width: "20px",
                height: "20px",
              }}
              style={{
                backgroundColor: color.color,
                border: color.isSelected ? "1px solid #11734A" : "none",
              }}
              className={classes.colorDiv}
              onClick={() => {
                // if (!props?.openPopupinEditMode?.status) {
                props.setColor(color.color);
                handleColorCheck(color.color);
                // }
              }}
            >
              {color.isSelected && (
                <CheckIcon
                  sx={{
                    color: "#ffff",
                    width: "18px",
                    height: "18px",
                    padding: "2px",
                  }}
                />
              )}
            </Box>
          ))}
        </div>
      </div>
      <div className={classes.frame4466}>
        <NewButton
          buttonText={"Save"}
          padding={"10px 16px"}
          borderRadius={"8px"}
          onClick={handleAddValue}
        />
        <NewButton
          buttonText={"Cancel"}
          padding={"10px 16px"}
          borderRadius={"8px"}
          variant="outlined"
          onClick={props.handleClose}
          textColor={palette.common.black}
        />
      </div>

      {/* <Typography
        variant="subtitle2"
        className={classes.deleteText}
        fontWeight={400}
        sx={{ mt: 3 }}
      >
        {
          props?.openPopupinEditMode?.status ? <Typography
            variant="caption"
            onClick={handleDeleteValue}>Delete this point</Typography>
            : <Typography
              variant="caption"
              onClick={handleAddValue}>Add This Point</Typography>
        }
      </Typography> */}
    </Popover>
  );
};

export default AddPopOver;
